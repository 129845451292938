import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactCodeInput from 'react-code-input'
import { useDispatch, useSelector } from 'react-redux'
import { acceptLoanAsync, getPendingDecisionsAsync, setCurrentDecisionAsync } from '../redux/actions/auth.action'
// import { useNavigate } from 'react-router-dom';
import { logoutAsync } from '../redux/actions/auth.action';
import Logo from './Logo';
import { convertToMoney } from '../utils';
import Logo3 from './Logo3';
import Footnotes from './Footnotes';


export default function AcceptLoan(){
    // const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phone, setPhone] = useState('')
    const [monthlyInterest, setMonthlyInterest] = useState(0)
    const {accessToken, userExists, loanId,
        companyDetails,decisionId, 
        current_decision, pending_decision,
    } = useSelector(
        (state) => state.auth
      );
    const [loading, setLoading] = useState(false)
    const [repaymentSchedules, setRepaymentSchedules] = useState([])
    const [selectedSchedule, setSelectedSchedule] = useState(0)
    const [token, setToken] = useState('')
    const [showError, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    
    const refreshPending = ()=>{
        dispatch(
        getPendingDecisionsAsync({accessToken,
            companyShortCode: companyDetails.shortCode
        },
        ()=>{
          if(decisionId !== null && pending_decision !== null){
            if(typeof pending_decision[0] !== 'undefined'){
              let m = pending_decision.filter((pd)=>{
                if(pd.id === decisionId){
                  return true
                }else{
                  return false
                }
              })
              dispatch(setCurrentDecisionAsync(
                {current_decision: m[0]}
              ))
            }
          }
        })
        )
      }
    const acceptLoan = () =>{
        if(!loading){
        let numberOfMonths = repaymentSchedules[selectedSchedule].numberOfMonths
        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
              },
          };
          setLoading(true)
          fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/accept-loan?decisionId=${decisionId}&selectedTenure=${numberOfMonths}`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }
                return response.json()
            })
            .then(result => {
                refreshPending()
                setLoading(false)
                dispatch(acceptLoanAsync(result), false, 
                ()=>{
                    // navigate('/success')
                })
            })
            .catch(error => 
                {
                    refreshPending()
                    setLoading(false)
                    dispatch(acceptLoanAsync(error, true, ()=>{
                        // navigate('/success')
                    }))
                });
        }
    }

  

 

    useEffect(()=>{
        if(typeof repaymentSchedules !== 'undefined' && typeof selectedSchedule !== 'undefined'){
            if(typeof repaymentSchedules[selectedSchedule] !== "undefined"){
                let r = repaymentSchedules[selectedSchedule]
                let x = Number(r.monthlyInterest)
                let y = Number(r.amountOfferred)
                let p = (x/y) * 100;
                p = (Math.round(p * 10) / 10).toFixed(1)
                setMonthlyInterest(p)
            }
        }
    },[selectedSchedule, repaymentSchedules])

    useEffect(()=>{
        if(typeof current_decision.repaymentSchedules !== "undefined"){
            setRepaymentSchedules(current_decision.repaymentSchedules)
        }
    },[current_decision])
    
    return (
        <div className='bnpl_modal'>
        <>
            <Logo3 />
            <div className='modal_content'>
            {typeof selectedSchedule !== 'undefined' && typeof repaymentSchedules !== 'undefined' &&
                <div className="form">
                    <div>
                        {typeof repaymentSchedules !== 'undefined' &&
                        <>
                            <label>Select Preferred Schedule</label>
                            <select 
                            onChange={(e)=>{
                                setSelectedSchedule(e.target.value)
                                
                            }}>
                                <option value={""} disabled>Select Preferred Schedule</option>
                                {repaymentSchedules.map((rp, index)=>{
                                    return(
                                    <option value={index} key={index}>{rp.numberOfMonths} Month{Number(rp.numberOfMonths) > 1 ? 's':''} Schedule</option>
                                    )
                                })}
                            </select>
                            
                            {/* {typeof repaymentSchedules[0] !== 'undefined' &&
                            <div className='loan_summary'>
                                <div className='summary_list'>
                                    <div className='title_'>Duration: </div>
                                    <div className='value'>{repaymentSchedules[selectedSchedule].numberOfMonths} Months</div>
                                </div>
                                <div className='summary_list'>
                                    <div className='title_'>Amount Offered: </div>
                                    <div className='value'>{convertToMoney(repaymentSchedules[selectedSchedule].amountOfferred)}</div>
                                </div>
                                <div className='summary_list'>
                                    <div className='title_'>Upfront Payment: </div>
                                    <div className='value'>{convertToMoney(repaymentSchedules[selectedSchedule].upfrontPayment)}</div>
                                </div>
                                <div className='summary_list'>
                                    <div className='title_'>Monthly Repayment Plus Interest: </div>
                                    <div className='value'>{convertToMoney(repaymentSchedules[selectedSchedule].monthlyInstallmentWithInterest)}</div>
                                </div>
                                <div className='summary_list'>
                                    <div className='title_'>Monthly Interest ({monthlyInterest}%): </div>
                                    <div className='value'>{convertToMoney(repaymentSchedules[selectedSchedule].monthlyInterest)}</div>
                                </div>    
                            </div>
                            } */}


                            {typeof repaymentSchedules[0] !== 'undefined' && selectedSchedule !== '' &&
                            <div className='payment_summary'>
                                <div className='title__'>Payment Plan</div>
                                <div className='caption'> You pay <span>{convertToMoney(repaymentSchedules[selectedSchedule].monthlyInstallmentWithInterest)}</span> per month for <span>{repaymentSchedules[selectedSchedule].numberOfMonths}</span> months</div>
                                <div className='payment_summary_details'>
                                    
                                    {typeof companyDetails.interestRate !== "undefined" &&
                                    <div className='inner'>
                                        <div className='title_'>Monthly Service Fee</div>
                                        <div className='value'>{companyDetails.interestRate}% </div>
                                    </div>
                                    }
                                    <div className='divider'></div>
                                    <div className='inner'>
                                        <div className='title_'>UPFRONT</div>
                                        <div className='value'>{convertToMoney(repaymentSchedules[selectedSchedule].upfrontPayment)}</div>
                                    </div>
                                    <div className='divider'></div>
                                    <div className='inner'>
                                        <div className='title_'>BNPL AMOUNT</div>
                                        <div className='value'>{convertToMoney(repaymentSchedules[selectedSchedule].amountOfferred)}</div>
                                    </div>
                                </div>
                            </div>
                            }

                            
                            
                            <div className="btn btn-primary"
                            onClick={()=>{
                                acceptLoan()
                            }}
                            >Accept BNPL</div>

                            {loading && 
                            <div className='loading_text'>...Accepting BNPL Request</div>
                            }
                        </>
                    }
                        
                    </div>
                
                {showError && 
                        <div className='loading_text error'>{errorText}</div>
                }
                </div>
            }
            </div>
        </>
        
        <Footnotes />
        </div>
    );
}
