import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import next from '../../assets/next.svg'
import back from '../../assets/back.svg'
import Logo3 from '../../components/Logo3';
import { useSelector, useDispatch } from 'react-redux';
import StorageService from '../../utils/storageService';

import { logoutAsync, userDetailsAsync, getUserWalletAsync, makePreferredPaymentAsync, getWalletStatusAsync } from '../../redux/actions';
import { convertToMoney } from '../../utils';


export default function AddPaymentOptions({
    current_decision, setPreferredPayment, preferredPayment, setSelectedPreferred
}) {
    const dispatch = useDispatch()
    const { 

        accessToken, companyDetails, userDetails, 
        walletDetails, successMessage, consentURL, wallet_status

    } = useSelector(
        (state) => state.auth
    );

    const paymentOptions = {  
        '1': 'Card',
        '2': 'Wallet'
    }
    const storageService = new StorageService()
    const navigate = useNavigate()
    const [walletCreated, setWalletCreated] = useState(false)
    const [bankLinked, setbankLinked] = useState(true)
    const [allowedPaymentMethods, setAllowedPaymentMethod] = useState([])
    
    useEffect(()=>{
        let auth_data = storageService.getAuthData()
        if(typeof auth_data.accessToken !== "undefined"){
            dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
            dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))   
            dispatch(getWalletStatusAsync({accessToken: auth_data.accessToken}))   
        }
    },[])

    useEffect(()=>{
        if(typeof walletDetails !== "undefined"){
            if(typeof walletDetails.accounts !== "undefined"){
                setWalletCreated(true)
            }else{
                setWalletCreated(false)
            }
        }
    },[walletDetails])
    
    useEffect(()=>{
      if(typeof userDetails.paymentMethod !== "undefined"){
        setPreferredPayment(userDetails.paymentMethod)
      }
    },[userDetails])

    useEffect(()=>{
        if(typeof userDetails.paymentDetailsVerified !== "undefined"){
            setbankLinked(userDetails.paymentDetailsVerified)
        }
    },[userDetails])

    useEffect(()=>{
        if(typeof companyDetails.allowedPaymentMethods !== "undefined"){
            setAllowedPaymentMethod(companyDetails.allowedPaymentMethods)
        }
    },[companyDetails])



    return (
    <div className='main'>
        
        <div className='izi_box bnpl_modal'>
            <Logo3 />
            


            {accessToken !== '' && typeof userDetails !== 'undefined' &&
                <div className='logout bg-gray-800'
                    onClick={()=>{
                    dispatch(logoutAsync())
                    }}
                >Logout</div>
            }
                <div className='flex flex-row mt-[20px] justify-between'>
                    <div className='flex flex-row gap-[20px] py-[5px] cursor-pointer'>
                        <div className='text-xs 
                        bg-gray-200 py-[4px] px-[10px]
                        flex flex-row rounded-[13px]'
                            onClick={()=>navigate('/')}
                        >
                            <img src={back} className='w-[15px] h-[15px]' alt='back_button'/>
                            <div className=''></div>
                        </div>
                        <div className='text-xs pt-[3px]
                        font-bold
                        '>{`Select payment Method`}</div>
                    </div>
                </div>
            
                
                <div className='payment_list pt-[20px]'>
                    
                    {allowedPaymentMethods.includes('Wallet') &&
                    <div>
                        <div className='
                            bg-white  my-[5px] rounded-[5px] text-xs flex flex-row justify-between
                        '>
                            <div className='flex flex-row gap-[10px] justify-between py-[10px] px-[20px] '>
                                {walletCreated &&
                                <div className={`radio h-[20px] w-[20px]
                                    ${preferredPayment === paymentOptions['2'] ? 'border border-black':'bg-gray-100'} 
                                    cursor-pointer`}
                                onClick={()=>{
                                    let auth_data = storageService.getAuthData()
                                    if(typeof auth_data.accessToken !== "undefined"){
                                        dispatch(makePreferredPaymentAsync({
                                            decisionId:current_decision.id,
                                            option: paymentOptions['2'],
                                            accessToken: auth_data.accessToken},
                                            false,
                                            ()=>{
                                                    setPreferredPayment(paymentOptions['2'])
                                                    // dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))
                                                    // dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
                                                
                                            }))
                                    }
                                }}
                                >
                                    {preferredPayment === paymentOptions['2'] &&
                                    <div className='h-[13px] w-[13px] 
                                    bg-orange-500 my-[1.75px] mx-[2.5px]'
                                    // style={{background: 'var(--primaryColourHexCode)'}}
                                    ></div>}
                                </div>
                                }
                                <div className='pt-[2px] cursor-pointer'>Wallet</div>
                                
                            </div>
                            
                            {!walletCreated &&
                            <div>
                                <div 
                                className='p-[12px] font-bold 
                                cursor-pointer
                                text-orange-400
                                '
                                
                                >
                                    No wallet attached
                                </div>
                            </div>
                            }
                            
                        </div>
                        {!walletCreated &&
                        <div>
                            <div 
                                className='
                                cursor-pointer
                                hover:text-orange-400
                                p-[10px] h-[35px] 
                                text-xs  rounded-[5px] text-orange-500 font-bold w-[250px] cursor-pointer
                                '
                                onClick={()=>navigate('/create-wallet')}
                                >
                                    Create Wallet
                            </div>
                        </div>
                        }
                        {walletCreated &&  typeof walletDetails !== "undefined" &&
                        <div className='bg-gray-200 h-auto w-full p-[15px] rounded-[5px]'>
                            {typeof walletDetails.accounts !== "undefined" && 
                                <div>
                                {typeof walletDetails.accounts[0] !== "undefined" &&
                                    <div>
                                    {preferredPayment ===  paymentOptions['2'] &&
                                        <div className='pb-[10px] text-xs font-bold'
                                        style={{
                                            color:'var(--primaryColourHexCode)'
                                        }}
                                        >Preferred</div>
                                    }
                                    <div className='text-[10px] py-[3px]'>
                                        Balance: 
                                        <span className='font-bold px-[10px]'>
                                        {convertToMoney(Number(walletDetails.balance))}
                                    </span>
                                    </div>
                                    <div className='text-[10px] py-[3px]'>
                                        Wallet account no: 
                                        <span className='font-bold px-[10px]'>{walletDetails.accounts[0].accountNumber}</span>
                                    </div>
                                    <div className='text-[10px] py-[3px]'>
                                        Bank: 
                                        <span className='font-bold px-[10px]'>VFD Microfinance bank</span>
                                    </div>
                                    {/* {preferredPayment !== paymentOptions['2'] &&
                                    <div className='p-[10px] h-[35px] 
                                    text-xs bg-orange-500 rounded-[5px] 
                                    text-white mt-[10px] w-[250px] cursor-pointer'
                                    style={{
                                        background:'var(--primaryColourHexCode)'
                                    }}
                                    onClick={()=>{
                                        let auth_data = storageService.getAuthData()
                                        if(typeof auth_data.accessToken !== "undefined"){
                                            dispatch(makePreferredPaymentAsync({
                                                option: paymentOptions['2'],
                                                decisionId:current_decision.id,
                                                accessToken: auth_data.accessToken},
                                                false,
                                                ()=>{
                                                    setPreferredPayment(paymentOptions['2'])
                                                    let auth_data = storageService.getAuthData()
                                                    if(typeof auth_data.accessToken !== "undefined"){
                                                        dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))
                                                        dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
                                                    }
                                                }))
                                        }
                                    }}
                                    >
                                        Set as preferred payment method
                                    </div>
                                    } */}
                                    
                                    </div>
                                }
                                </div>
                            }
                        </div>
                        }
                        
                        {walletCreated &&  typeof walletDetails !== "undefined" && typeof wallet_status !== "undefined" &&
                        <>

                        {!wallet_status.succeded &&
                            <a href={walletDetails.consentUrl} target="_blank" rel="noreferrer">
                                {typeof walletDetails.consentUrl !== "undefined" &&
                                <div
                                className="
                                py-[10px] saira-600 text-[10px] text-center
                                cursor-pointer 
                                w-full hover:text-orange-700
                                "
                                >
                                    Click here to here to give consent to this platform to use your wallet. 
                                    Please ingore if you have already granted the consent.
                                </div>
                                }
                            </a>
                        }
                        </>
                        }
                    </div>
                    }

                    <div className='w-full h-[1px] bg-gray-400
                    mt-[20px] mb-[20px]
                    '></div>
                    
                    {allowedPaymentMethods.includes('Card') &&
                    <div>
                        <div className='
                            mt-[10px] mb-[5px] bg-white py-[10px] px-[20px] rounded-[5px] text-xs flex flex-row justify-between
                        '>
                            <div className='flex flex-row gap-[10px]'>
                                {bankLinked &&
                                <div className={`radio h-[20px] w-[20px] 
                                ${preferredPayment === paymentOptions['1'] ? 'border selected-radio border-black':'bg-gray-100'} 
                                cursor-pointer`}
                                onClick={()=>{
                                    let auth_data = storageService.getAuthData()
                                    if(typeof auth_data.accessToken !== "undefined"){
                                        dispatch(makePreferredPaymentAsync({
                                            decisionId:current_decision.id,
                                            option: paymentOptions['1'],
                                            accessToken: auth_data.accessToken},
                                            false,
                                            ()=>{
                                                    setPreferredPayment(paymentOptions['1'])
                                                    dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))
                                                    dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
                                            }))
                                    }
                                }}
                                
                                >
                                    {preferredPayment === paymentOptions['1'] && 
                                    
                                    <div className='h-[13px] w-[13px] bg-orange-500
                                    my-[1.75px] mx-[2.5px]'
                                    // style={{
                                    //     background:'var(--primaryColourHexCode)'
                                    // }}
                                    ></div>
                                    
                                    }
                                </div>
                                }
                                <div className='pt-[2px] cursor-pointer font-bold'>Debit Card</div>
                            </div>
                            {!bankLinked &&
                                    <div>
                                        <div 
                                        className='text-xs font-bold text-orange-400
                                        '
                                        onClick={()=>navigate('/card')}
                                        >
                                            No card linked
                                        </div>
                                    </div>
                            }
                            {bankLinked &&
                                <div 
                                        className='font-bold 
                                        cursor-pointer
                                        hover:text-orange-400'
                                        onClick={()=>navigate('/card')}
                                        >
                                            Update
                                </div>
                            }
                        </div>
                        {!bankLinked &&
                        <div>
                            <div 
                                className='
                                cursor-pointer
                                hover:text-orange-400
                                p-[10px] h-[35px] 
                                text-xs rounded-[5px] text-orange-500 font-bold w-[150px] cursor-pointer
                                '
                                onClick={()=>navigate('/card')}
                                >
                                    Add Card Details
                            </div>
                        </div>
                        }
                        {bankLinked &&
                        <div className='bg-gray-200 h-auto w-full p-[15px] rounded-[5px]'>
                            {preferredPayment === paymentOptions['1'] &&
                                <div className='pb-[10px] text-xs font-bold'
                                style={{
                                    color:'var(--primaryColourHexCode)'
                                }}
                                >Preferred</div>
                            }
                            {/* {preferredPayment !== paymentOptions['1'] &&
                            <div className='p-[10px] h-[35px] 
                                text-xs bg-orange-500 rounded-[5px] 
                                text-white w-[250px] cursor-pointer'
                                style={{
                                        background:'var(--primaryColourHexCode)'
                                    }}
                                onClick={()=>{
                                    let auth_data = storageService.getAuthData()
                                    if(typeof auth_data.accessToken !== "undefined"){
                                        dispatch(makePreferredPaymentAsync({
                                            option: paymentOptions['1'],
                                            decisionId:current_decision.id,
                                            accessToken: auth_data.accessToken},
                                            false,
                                            ()=>{
                                                setPreferredPayment(paymentOptions['1'])
                                                let auth_data = storageService.getAuthData()
                                                if(typeof auth_data.accessToken !== "undefined"){
                                                    dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))
                                                    dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
                                                }
                                            }))
                                    }
                                }}
                                >
                                    Set as preferred payment method
                            </div>
                            } */}
                        </div>
                        }
                    </div>
                    }
                    
                    
                    {preferredPayment !== "" &&
                    <div className='w-full h-[50px] mt-[20px] bg-blue-600 text-center pt-[13px] rounded-[5px] text-white'
                    style={{
                            background:'var(--primaryColourHexCode)'
                        }}
                    onClick={()=>{
                        setSelectedPreferred(true)
                    }}
                    >Continue</div>
                    }
                   
                </div>
        </div>
    </div>
  )
}
