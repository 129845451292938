import React,{useState, useEffect} from 'react'
import { useSelector} from 'react-redux'

function Logo() {
  const [companyChecked, setCompanyChecked] = useState(false)
  const {companyDetails
  } = useSelector(
      (state) => state.auth
    );
    useEffect(()=>{
        if(typeof companyDetails !== 'undefined' && JSON.stringify(companyDetails) !== '{}'){
            if(typeof companyDetails.name !== 'undefined'){
                if(companyDetails.name === ''){
                    setCompanyChecked(false)
                }else{
                    setCompanyChecked(true)
                }
            }else{
                setCompanyChecked(false)
            }
        }else{
            setCompanyChecked(false)
        }
    },[companyDetails])
  return (
    <>
    <center>
        {companyChecked &&
        <img 
        className="logo" 
        src={ companyDetails.logoUrl} />
        }
        {!companyChecked &&
            <div className='izi_logo'></div>
        }
    </center>
    </>
  )
}

export default Logo

