import React from 'react'
import { useNavigate } from 'react-router-dom'
export default function PageNotFound() {
  const navigate = useNavigate()
  return (
    <div className='bnpl_modal'>
      <div className='pagenotfound'></div>
      <div className='btn btn-primary'
      onClick={()=>{
        navigate('/')
      }}
      >Go back</div>
    </div>
  )
}
