import {
  _getTokenFromStorage,
  _removeTokenFromStorage,
  _setTokenToStorage
} from "../utils";


Date.prototype.addHours= function(h){
  this.setHours(this.getHours()+h);
  return this;
}


export default class StorageService {
  saveAuthData(authData) {
    // const expirationTime = new Date(authData.expires_in * 1000);
    console.log(authData)
    const expirationTime = new Date().addHours(2)
    this.#saveItemIfProvided("accessToken", authData.accessToken, expirationTime);
    this.#saveItemIfProvided("role", authData.role, expirationTime);
    this.#saveItemIfProvided("authString", authData.authString, expirationTime);
    this.#saveItemIfProvided("applicationUser", JSON.stringify(authData.applicationUser), expirationTime);
    this.#saveItemIfProvided("isKYCCompleted", authData.isKYCCompleted, expirationTime);
    this.#saveItemIfProvided("isProfileCompleted", authData.isProfileCompleted, expirationTime);
    this.#saveItemIfProvided("email", authData.email)
    // this.#saveItemIfProvided("lastname", authData.userData.LastName, expirationTime);
  }

  #saveItemIfProvided(key, value, expiresAt) {
    if (value && expiresAt) {
      _setTokenToStorage(key, value, expiresAt);
    }
    else if (value) {
      _setTokenToStorage(key, value);
    }
  }

  clearAuthData() {
    _removeTokenFromStorage("accessToken")
    _removeTokenFromStorage("companyId")
    _removeTokenFromStorage("role")
    _removeTokenFromStorage("isKYCCompleted")
    _removeTokenFromStorage("isProfileCompleted")
    _removeTokenFromStorage("firstname")
    _removeTokenFromStorage("lastname")
    _removeTokenFromStorage('applicationUser')
  }

  clearCookieData() {
    _removeTokenFromStorage("accessToken")
    _removeTokenFromStorage("companyId")
    _removeTokenFromStorage("role")
    _removeTokenFromStorage("isKYCCompleted")
    _removeTokenFromStorage("isProfileCompleted")
    _removeTokenFromStorage("firstname")
    _removeTokenFromStorage("lastname")
    _removeTokenFromStorage('applicationUser')
  }

  set(key, value) {
    this.#saveItemIfProvided(key, value)
  }

  get(key) {
    return _getTokenFromStorage(key)
  }

  remove(key) {
    return _removeTokenFromStorage(key)
  }

  getAuthData() {
    return {
      accessToken: _getTokenFromStorage("accessToken"),
      companyId: _getTokenFromStorage("companyId"),
      firstName: _getTokenFromStorage("firstName"),
      lastName: _getTokenFromStorage("lastName"),
      role: _getTokenFromStorage("role"),
      isKYCCompleted: _getTokenFromStorage("isKYCCompleted"),
      isProfileCompleted: _getTokenFromStorage("isProfileCompleted"),
      authString: _getTokenFromStorage("authString"),
      applicationUser: _getTokenFromStorage('applicationUser')
    // this.#saveItemIfProvided("lastname", authData.use

    }
  }
}