import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { getPendingDecisionsAsync, requestLoanAsync, setCurrentDecisionAsync, setLoanResponseAsync } from '../redux/actions/auth.action'
import {logoutAsync} from '../redux/actions'
import Logo from './Logo';
import CurrencyInput from 'react-currency-input-field'
import { toast } from 'react-toastify';
import Logo2 from './Logo2';
import { useNavigate } from 'react-router-dom';
import Footnotes from './Footnotes';

export default function RequestLoan(){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phone, setPhone] = useState('')
    const [dataValid, setDataValid] = useState(false)

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    
    const {accessToken, userExists, tokenSent, 
        login_data, companyDetails, decisionId,
        pending_decision, userDetails
    } = useSelector(
        (state) => state.auth
    );

    const [decisioningQuestions, setDecisioningQuestions] = useState([])
    const [extraQuestions, setExtraQuestions] = useState({})

    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [showError, setError] = useState(false)
    const [showReference, setShowReference] = useState(true) 
    const [errorText, setErrorText] = useState("")
    const [data, setData] = useState(
        {
            "cartValue": "",
            "orderReference": "",
            "decisionId":"",
            "extraQuestions":{}
        }
    )
    const refreshPending = ()=>{
        dispatch(
        getPendingDecisionsAsync({accessToken, companyShortCode: companyDetails.shortCode},
        ()=>{
          if(decisionId !== null && pending_decision !== null){
            if(typeof pending_decision[0] !== 'undefined'){
              let m = pending_decision.filter((pd)=>{
                if(pd.id === decisionId){
                  return true
                }else{
                  return false
                }
              })
              dispatch(setCurrentDecisionAsync(
                {current_decision: m[0]}
              ))
            }
          }
        })
        )
      }
    
    const validateData = () =>{
        let validation = []
        decisioningQuestions.forEach(thisQuestion => {
            if(thisQuestion.type === "Number"){
                if(extraQuestions[thisQuestion.name] < 0 || isNaN(extraQuestions[thisQuestion.name])){
                    validation.push({
                        name: thisQuestion.name,
                        error: "Only numbers are allowed"
                    })
                }
            }else{
                if(extraQuestions[thisQuestion.name].text === ""){
                    validation.push({
                        name: thisQuestion.name,
                        error: "Response cannot be empty"
                    })
                } 
            }
        });
       
        if(validation.length > 1){
            setErrorText(validation[0].error) 
            setDataValid(false)
            return false
        }else if(isNaN(data.cartValue) || typeof data.cartValue === 'undefined'){
            // console.log(data.cartValue)
            setErrorText('Amount requested should be a number') 
            setDataValid(false)
            return false       
        }else if(data.orderReference === '' || typeof data.orderReference === 'undefined'){
            // console.log(data.orderReference)
            setErrorText('order reference is required, ')
            setDataValid(false)
            return false
        }else{
            // console.log('all clear')
            setDataValid(true)
            return true
        }
    }
    const requestLoan = () =>{
        let validated = validateData()
        if(errorText !== ''){
            toast.error(errorText, {
              position: "top-right",
              autoClose: 5000,
              onClose: () => {
                setErrorText('')
              }
            })
        }
        let raw = JSON.stringify({
            "decisionId": decisionId,
            "cartValue": data.cartValue,
            "orderReference": data.orderReference,
            "extraQuestions":extraQuestions
        })
        if(!loading && validated){
          var myHeaders = new Headers();
          myHeaders.append('Authorization', `Bearer ${accessToken}`)
          myHeaders.append('content-type', 'application/json')
          
          var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: raw,
            headers: myHeaders,
          };
          setLoading(true)
          fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/request-loan`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                refreshPending()
                // console.log("Request Loan Response",result)

                setLoading(false)
                if(!result.succeded){
                    // console.log("ReSULT")
                    dispatch(
                        setLoanResponseAsync({
                            status: false,
                            message: result.message
                        },
                        ()=>{
                            dispatch(setCurrentDecisionAsync({current_decision: []}))
                        }
                        ),
                    )
                }else{
                    dispatch(requestLoanAsync(result), false, 
                        ()=>{
                        },
                        ()=>{
                        }
                    )
                }
            })
            .catch(error => 
                {
                    if (error.status) {
                      // Handle the status code
                      console.error(`Error status code: ${error.status}`);
                      if(error.status === 400){
                        let message = error.message
                        setLoanResponseAsync({
                            status: false,
                            message
                        })
                      }
                    } else {
                        // Handle other errors
                        console.error('Fetch error:', error);
                    }
                    setLoading(false)
                    dispatch(requestLoanAsync(error, true, ()=>{}, 
                        ()=>{
                        })
                );
              }
            );
        }
    }

    // useEffect(()=>{
    // console.log(errorText)    
    //   },[errorText])
    
      useEffect(()=>{
        
        if(typeof companyDetails !== "undefined"){
            // console.log(companyDetails)
            if(typeof companyDetails.loanApplicationQuestions !== "undefined"){
                setDecisioningQuestions(companyDetails.loanApplicationQuestions)
                companyDetails.loanApplicationQuestions.forEach(thisQuestion => {
                    let thisQuestionValue
                    if(thisQuestion.type === "Number"){
                        thisQuestionValue = 0
                    }else if(thisQuestion.type === "Options"){
                        thisQuestionValue = thisQuestion.options[0].text
                    }else{
                        thisQuestionValue = ""
                    }
                    // setData({...data, [name]: value.toString().trim()})
                    // console.log(thisQuestion.name, "...", thisQuestionValue)
                    let m = extraQuestions
                    m[thisQuestion.name] = thisQuestionValue
                    setExtraQuestions(m)
                });
            }
            
            let orderReference = data.orderReference
            if(typeof companyDetails.shouldGenerateOrderReference !== "undefined"){
                if(companyDetails.shouldGenerateOrderReference === true){
                    setShowReference(false)
                }else{
                    setShowReference(true)
                    let shortcode = companyDetails.shortCode
                    let customer_id = userDetails.id
                    const timestamp = Date.now();
                    orderReference = `${shortcode}-${customer_id}-${timestamp}`
                    
                }
            }

            
            let dta = {...data}
            dta.orderReference = orderReference
            setData(dta)
            
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[companyDetails])
      
    return (
        <>
        <div className='bnpl_modal'>
        {accessToken !== '' &&
          <div className='logout bg-gray-800'
            onClick={()=>{
              dispatch(logoutAsync())
            }}
          >Logout</div>
        }
            {/* <Logo2 /> */}
            <div className='profile_name'
                onClick={()=>{
                navigate('/history')
                }}
                >
                <div className='icon_ back'></div>
            {`Back to home`}</div>
            <div className='modal_content'>
                <div className="form">
                    <div>
                        <>
                            {decisioningQuestions.map((thisQuestion, index)=>{
                                    return(
                                    <div key={index}>
                                        <label>{thisQuestion.text}</label>
                                        {thisQuestion.type !== "Options" &&
                                            <input 
                                                className='h-[50px]'
                                                type={`text`}
                                                value={(typeof data.extraQuestions !== "undefined" && typeof thisQuestion.name !== "undefined") ? 
                                                        (typeof data.extraQuestions[thisQuestion.name] !== "undefined") ?
                                                            data.extraQuestions[thisQuestion.name] : ''
                                                        : '' }
                                                onChange={(e)=>{

                                                    let type = thisQuestion.type
                                                    // thisQuestion.type === "Number" ? "number" : "text"
                                                    // e.target.value.toString()
                                                    let value = e.target.value.toString()
                                                    let trimmed_value = value.toString().trim()
                                                    let numbered_value = Number(trimmed_value)
                                                    if(type === "Number" && value === ''){
                                                        numbered_value = 0
                                                    }
                                                    

                                                    if((type === "Number" && /^\d*$/.test(numbered_value) && Number(numbered_value) >= 0 ) 
                                                        || type !== "Number" ){
                                                        let key = thisQuestion.name
                                                        let dta = {...data}
                                                        dta.extraQuestions[key] = trimmed_value
                                                        setData(dta)
                                                        validateData()
                                                    }
                                                }}
                                            />
                                        }
                                        {thisQuestion.type === "Options" &&
                                            <>
                                                <select
                                                    onChange={(e)=>{
                                                        let key = thisQuestion.name
                                                        let dta = {...data}
                                                        let value = e.target.value.toString()
                                                        let trimmed_value = value.toString().trim()
                                                        dta.extraQuestions[key] = trimmed_value
                                                        setData(dta)
                                                        validateData()
                                                    }}
                                                >
                                                    {thisQuestion.options.map((thisOption, index)=>{
                                                        return(
                                                            <option key={index}
                                                            value={thisOption.text}
                                                            >{thisOption.text}</option>
                                                        )
                                                    })}
                                                </select>
                                            </>
                                        }
                                        
                                    </div>
                                    )
                            })}
                        </>
                        <>
                            <label>Amount</label>
                            <CurrencyInput
                            id="input-example"
                            name="cartValue"
                            placeholder="Please enter the amount"
                            decimalsLimit={0}
                            prefix={`₦`}
                            onValueChange={(value, name) => {
                                if(value !== "" && typeof value !== "undefined"){
                                    let dta = {...data}
                                    let trimmed_value = value.toString().trim()
                                    dta[name] = trimmed_value
                                    //console.log(data, dta)
                                    setData(dta)
                                    // setData({...data, [name]: value.toString().trim()})
                                }else{
                                    let dta = {...data}
                                    // let trimmed_value = value.toString().trim()
                                    dta[name] = 0
                                    //console.log(data, dta)
                                    setData(dta)
                                }
                                validateData()
                            }}
                            />

                            {showReference &&
                            <div>
                            <label>Order Reference</label>
                            <input 
                                className='h-[50px]'
                                onChange={(e)=>{
                                    let dta = {...data}
                                    let value = e.target.value.toString()
                                    let trimmed_value = value.toString().trim()
                                    dta.orderReference = trimmed_value
                                    setData(dta)



                                    validateData()
                                }}
                            />
                            </div>
                            }
                           
                            {dataValid &&
                            <div className={`
                            ${ data.cartValue !== '' && data.cartValue !== 0
                            && data.orderReference !== '' && data.orderReference !== null
                            ? 'btn bg-gray-200' 
                            : 'h-[50px] pt-[13px] text-gray-600 cursor-pointer bg-gray-300 text-center rounded-[5px] text-sm '}
                            `}
                            onClick={()=>{
                                if(data.cartValue !== '' && data.cartValue !== 0 
                                && data.orderReference !== '' && data.orderReference !== null){
                                requestLoan()
                                }
                            }}
                            >Request BNPL</div>
                            }

                            {!dataValid &&
                            <div className={`
                            ? 'btn bg-gray-200' 
                            : 'h-[50px] pt-[13px] text-gray-600 cursor-pointer bg-gray-300 text-center rounded-[5px] text-sm '}
                            `}
                            >Request BNPL</div>
                            }

                            {loading && 
                            <div className='loading_text'>...Submitting BNPL Request</div>
                            }
                        </>
                    
                        
                    </div>
                
                {showError && 
                        <div className='loading_text error'>{errorText}</div>
                }
                </div>
            </div>
            <Footnotes />
        </div>
        </>
    );
}
