import StorageService from "./storageService";
import IdentityService from "./identityService";
// import Loan from './loan'
// import ProcessorService from "./processorService";
// import WalletService from "./walletService";
import LoanService from "./loanService"

export const identityService = new IdentityService();
// export const loan = new Loan();
export const storageService = new StorageService();
// export const processorService = new ProcessorService();
// export const walletService = new WalletService();
export const loanService = new LoanService();