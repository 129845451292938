import React from 'react'
import verified from '../../assets/verified.svg'

export default function DecisionSteps() {
  return (
    <div className='main'>
        <div className='izi_box bnpl_modal'>
            <div className=''>
                
                {/* <div className='w-[150px]'></div> */}
                <div className='w-full font-bold text-center font-gray-700 mb-[30px]'>Verify your identity</div>
                <img src={verified} className='h-[40px] my-[10px] m-auto'/>
                <div className='relative'>
                    
                    <div className='flex'>
                        
                        <div className='w-full'>
                            <div className='font-medium text-[12px] mb-[20px] text-left'>
                                To help protect you from fraud and identity theft, and comply with federal regulations, we need some info including:
                            </div>

                            <ul className="list-disc">
                                <li className='font-medium text-[12px] mb-[10px] text-left'>Selfie</li>
                                <li className='font-medium text-[12px] mb-[10px] text-left'>Data page of your government issued ID i.e. drivers license, passport or NIN</li>
                            </ul>
                        </div>
                    </div>
                    
                    
                    {/* <div className='font-bold text-gray-700'></div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                    <div className='font-bold text-gray-700'>Bank Statement</div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                    <div className='font-bold text-gray-700'>Verify Identity</div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                    <div className='font-bold text-gray-700'>Accept BNPL Offer</div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}
