import {
  _getTokenFromStorage,
  _removeTokenFromStorage,
  _setTokenToStorage
} from "./index.js";

Date.prototype.addHours= function(h){
  this.setHours(this.getHours()+h);
  return this;
}



export default class StorageService {
  saveAuthData(authData) {
    const expirationTime = new Date().addHours(12)
    this.#saveItemIfProvided("accessToken", authData.accessToken, expirationTime);
    this.#saveItemIfProvided("refreshToken", authData.refereshToken, expirationTime);
    this.#saveItemIfProvided("email", authData.email)
    this.#saveItemIfProvided("companyDetails", authData.companyDetails)
    this.#saveItemIfProvided("userDetails", authData.userDetails)
    this.#saveItemIfProvided("userId", authData.userId)
    // this.#saveItemIfProvided("phone", authData.phone)
  }

  savePhone(phone) {
    const expirationTime = new Date().addHours(12)
    this.#saveItemIfProvided("phone", phone)
  }
  addSessionData(sessionData){
    const expirationTime = new Date().addHours(12)
    this.#saveItemIfProvided("shortCode", sessionData.shortCode, expirationTime);
    this.#saveItemIfProvided("sessionId", sessionData.sessionId, expirationTime);
    this.#saveItemIfProvided("sessionUserId",sessionData.sessionUserId, expirationTime);
    this.#saveItemIfProvided("sessionTime",sessionData.sessionTime, expirationTime);
  }



  createEvent = (sessionId, action, accessToken) =>{
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      };
      fetch(`${process.env.REACT_APP_BACKEND_BASEURL}auth/create-event?sessionId=${sessionId}&action=${action}`, requestOptions)
        .then(response => { 
            return response.json()
        })
        .then(result => {
            console.log(result)
        })
        .catch(error => 
            {
              console.log(error)
            });
  }


  getEvents = (accessToken) =>{
    var session_data = this.getSessionData()
    if(typeof session_data.sessionId !== 'undefined'){
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      };
      fetch(`${process.env.REACT_APP_BACKEND_BASEURL}auth/get-events?sessionId=${session_data.sessionId}`, requestOptions)
        .then(response => { 
            return response.json()
        })
        .then(result => {
            console.log(result)
            if(typeof result.data !== "undefined"){
              this.addEventsData(result.data)
            }
        })
        .catch(error => 
            {
              console.log(error)
            });
      }
  }
  getSessionData() {
    return {
      userId: _getTokenFromStorage("userId"),
      shortCode: _getTokenFromStorage("shortCode"),
      sessionId: _getTokenFromStorage("sessionId"),
      sessionUserId: _getTokenFromStorage("sessionUserId"),
      sessionTime: _getTokenFromStorage("sessionTime")
    }
  }

  addEventsData(events) {
    let e = JSON.stringify(events)
    const expirationTime = new Date().addHours(12)
    this.#saveItemIfProvided("events", e,expirationTime);
  }

  addEventsData(events) {
    let e = JSON.stringify(events)
    if(typeof e[0] !== "undefined" ){
      const expirationTime = new Date().addHours(12)
      this.#saveItemIfProvided("events", e,expirationTime);
    }
  }

  getEventsFromStorage(){
    let e = _getTokenFromStorage("events")
    if(typeof e !== "undefined"){
      let parsed_e = JSON.parse(e)
      return parsed_e
    }
    
  }
  #saveItemIfProvided(key, value, expiresAt) {
    if (value && expiresAt) {
      _setTokenToStorage(key, value, expiresAt);
    }
    else if (value) {
      _setTokenToStorage(key, value);
    }

  }
  clearSessionData(){
    _removeTokenFromStorage("shortCode")
    _removeTokenFromStorage("sessionId")
    _removeTokenFromStorage("userId")
  }
  clearAuthData() {
    _removeTokenFromStorage("accessToken")
    _removeTokenFromStorage("refreshToken")
    _removeTokenFromStorage("email")
    _removeTokenFromStorage("companyDetails")
    _removeTokenFromStorage("userDetails")
    // _removeTokenFromStorage("phone")
  }

  clearCookieData() {
    _removeTokenFromStorage("accessToken")
    _removeTokenFromStorage("refreshToken")
    _removeTokenFromStorage("email")
    _removeTokenFromStorage("companyDetails")
    _removeTokenFromStorage("userDetails")
    _removeTokenFromStorage("phone")
  }

  set(key, value) {
    this.#saveItemIfProvided(key, value)
  }

  get(key) {
    return _getTokenFromStorage(key)
  }

  remove(key) {
    return _removeTokenFromStorage(key)
  }

  getAuthData() {
    return {
      accessToken: _getTokenFromStorage("accessToken"),
      companyId: _getTokenFromStorage("refreshToken"),
      firstName: _getTokenFromStorage("email"),
      userId: _getTokenFromStorage("userId"),
      phone: _getTokenFromStorage("phone")
    // this.#saveItemIfProvided("lastname", authData.use

    }
  }
}