import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam'

function CaptureSelfie(
  {
    photoFile, setPhotoFile,
    photoSrc, setPhotoSrc,
    next
  }
) {

  const [videoConstraints, setVideoConstraints] = useState({
    width: 240,
    height: 200,
    facingMode: 'user',
  })

  const switchCamera = () =>{
    let v = videoConstraints.facingMode
    if(v === 'user'){
      v = 'environment'
    }else{
      v = 'user'
    }
    setVideoConstraints({...videoConstraints, facingMode: v})
  }
  const camera = useRef(null);

  const captureImage = async () => {
    if (photoSrc === "" || photoSrc === null) {
      const pictureSrc = camera.current.getScreenshot()
      setPhotoSrc(pictureSrc)
      setPhotoFile(pictureSrc)
    } else {
      next()
    }
  }


  return (
    <>
      <div className='kyc_box'>
        <div className="title">Take a selfie</div>


        <div className='image_box camera-div' style={{
          width: "300px"
        }}>
          {(photoSrc === null || photoSrc === "") &&
            <span>
              <Webcam
                audio={false}
                height={300}
                ref={camera}
                width={300}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <div className='switch_camera'
                onClick={() => switchCamera()}
              ></div>
            </span>
          }
          {(photoSrc !== null && photoSrc !== "") &&
            <span>
              <div className='cancel' onClick={() => setPhotoSrc("")}></div>
              <img src={photoSrc} width="100%" height="100%" />
            </span>
          }
        </div>

        


        <div className='s'>
          <div className={`btn ${photoSrc !== null && photoSrc !== "" ? "btn-primary" : "btn-primary"}`}
            onClick={() => { captureImage() }}
          >
            {(photoSrc === null || photoSrc === "") &&
              "Capture Image"}

            {(photoSrc !== null && photoSrc !== "") &&
              "Continue"}

          </div>
        </div>
      </div>
    </>
  )
}

export default CaptureSelfie