import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAsync
} from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import Logo3 from './Logo3';
import check from '../assets/check.svg'
import Footnotes from './Footnotes';
export default function DataUseTerms({
    termsRead=false, setTermsRead=()=>{}, showConfirm=true
}) {
    
    const dispatch = useDispatch()
    const {accessToken, companyDetails,
    } = useSelector(
        (state) => state.auth
    )
    const [understood, setUnderstood] = useState(false)

    return (
        <div className='izi_box bnpl_modal'>
            <Logo3 
                title={typeof companyDetails !== "undefined" ? companyDetails.name : ""}
            />
            {accessToken !== '' && typeof userDetails !== 'undefined' &&
                <div className='logout bg-gray-800'
                    onClick={()=>{
                    dispatch(logoutAsync())
                    }}
                >Logout</div>
            }
            <div
                className='m-auto text-center mt-[40px] mb-[20px] font-bold'
            >Terms of use</div>
            <div 
            className='m-auto '
            >
                <div 
                className='terms m-auto p-[20px] bg-gray-200
                text-[12px] max-h-[400px] overflow-auto'
                >
                    <p>These Terms of Use govern your access to and use of this website, including any subdomain thereof, as well as any other websites and mobile applications operated by Izifin/Company/Us (collectively, the "Services"). The Services include the content, information, products, features, and resources available or enabled via the Website and/or mobile application (the &ldquo;Application&rdquo;). The Services and the information provided on them are controlled by Izifin. By using the Services, including your Izibnpl account (your "Account"), you (customer) agree to these Terms of Use.&nbsp;</p>
                    <p>By agreeing to these Terms of Use, completing the registration process, browsing the Website, using any of the Services, or downloading or using the Application, you represent that:&nbsp;</p>
                    <p>(1) you have read, understood, and agree to be bound by these Terms and Conditions;&nbsp;</p>
                    <p>(2) you are of legal age to form a binding contract with Izifin; and&nbsp;</p>
                    <p>(3) you have the authority to enter into these Terms of Use personally;</p>
                    <p>These Terms and Conditions form a binding legal agreement between you and Izifin.&nbsp;</p>
                    <p>Additionally, your use of the Services may be governed by supplemental terms, conditions, policies, and agreements (the &ldquo;Supplemental Terms&rdquo;), which are incorporated by reference into this Agreement.</p>
                    <p>You acknowledge and agree that by clicking &ldquo;accept&rdquo; you accept Izibnpl's offer and enter into a binding contract for the Account.</p>
                    <ol>
                    <li>
                    <p>Definitions</p>
                    </li>
                    </ol>
                    <p><strong>BNPL</strong>&nbsp;refers to Buy Now, Pay Later.</p>
                    <p><strong>Card Data</strong>&nbsp;refers to all personal, financial, card, or transaction information obtained from a cardholder in connection with a BNPL transaction.</p>
                    <p><strong>Customer</strong>&nbsp;refers to individuals who use BNPL options for their purchases.</p>
                    <p><strong>Invoice</strong>&nbsp;(Order or Receipt) means the document provided to you by the Merchant at the time of&nbsp;<strong>Purchase</strong>&nbsp;recording details of the Purchase.</p>
                    <p><strong>Merchant</strong>&nbsp;refers to businesses that offer BNPL options for their goods or services to their customers via our service.&nbsp;</p>
                    <p><strong>Password/PIN</strong>&nbsp;means the secret number/code used by you to access your Account.</p>
                    <p><strong>Registered User</strong>&nbsp;is someone who has registered an Account.</p>
                    <p><strong>Services</strong>&nbsp;refer to the BNPL services provided by Izifin.</p>
                    <ol start="2">
                    <li>
                    <p>Services</p>
                    </li>
                    </ol>
                    <p>Izifin provides a platform that acts as a middleman/API for Buy Now, Pay Later (BNPL) services between merchants and customers.</p>
                    <p>Our services include, but are not limited to, the following:</p>
                    <ol>
                    <li>
                    <p>Facilitating BNPL transactions between merchants and customers.</p>
                    </li>
                    <li>
                    <p>Providing technical integration and support for merchants.</p>
                    </li>
                    <li>
                    <p>Processing and managing customer payments and repayment schedules.</p>
                    </li>
                    <li>
                    <p>Offering customer support related to BNPL transactions.</p>
                    </li>
                    </ol>
                    <p>We do not provide any goods or services directly to customers; we merely facilitate the transaction between the merchant and the customer.</p>
                    <p>Any future release, update, or other addition to the Services shall be subject to this Agreement.&nbsp;</p>
                    <p>Izifin reserves all rights not granted in this Agreement. As a condition of use, you agree not to use the Services for any purpose that is prohibited by this Agreement or by applicable law.</p>
                    <ol start="3">
                    <li>
                    <p>Registration of Account</p>
                    </li>
                    </ol>
                    <p><strong>Customer</strong></p>
                    <p>To access certain features of the Services, you may need to become a Registered User. You agree that the registration information you provide, including any proof of income or other requested documentation (the "Registration Data"), is accurate, complete, and current. You also agree to promptly update the Registration Data to keep it true, accurate, current, and complete. By registering, you represent that you are:</p>
                    <p>(i) 18 years of age or older</p>
                    <p>(ii) able to form a binding contract with Izifin and any Merchant</p>
                    <p>(iii) not prohibited by law from accessing the Services and have not been banned, terminated, or otherwise denied access to the Services</p>
                    <p>(v) not acting on behalf of someone whose access to the Services has been previously terminated or otherwise denied by Izifin and/or the Merchant</p>
                    <p><strong>Merchant</strong></p>
                    <p>To access and utilize the Services, you must become a registered merchant. By registering, you agree that all registration information you provide, including any business documentation or other requested materials (the "Registration Data"), is accurate, complete, and current. You also agree to promptly update the Registration Data to keep it accurate, complete, and up to date. By registering, you represent and warrant that you:</p>
                    <p>(i) are a legally constituted entity;</p>
                    <p>(ii) have the authority to enter into a binding contract with Izifin;</p>
                    <p>(iii) are not prohibited by law from providing the Services to customers;</p>
                    <p>(iv) are not acting on behalf of a business entity or individual whose ability to provide the services to customers has been previously terminated or otherwise denied.</p>
                    <ol start="4">
                    <li>
                    <p>Password</p>
                    </li>
                    </ol>
                    <p>After registering, you will receive or be able to create a username and password. You are responsible for maintaining the confidentiality of your username and password and agree not to disclose your password to any third party (except to any agent, representative, or third-party service provider of Izifin) and to exit from your Account at the end of each session.</p>
                    <p>Izifin disclaims all liability for any harm or damages resulting from the theft or unauthorized use of your username or password. If you become aware of any unauthorized use, loss, or theft of your username, password, or other account information, you agree to immediately inform us at the contact details provided below.</p>
                    <p>Izifin will issue a password granting merchants and/or their representatives and agents access to a merchant portal that provides a dashboard and information regarding BNPL transactions (the "Merchant Portal"). Merchants agree to restrict use and access to the password to authorized representatives and agents on an as-needed basis and ensure that each representative or agent is aware of and complies with the terms of this agreement. Merchants are solely responsible for maintaining adequate security and control of any passwords and credentials issued by Izifin.</p>
                    <p>You must keep your Password/PIN confidential and secure. This applies to both merchants and customers, as anyone with access to your Password or PIN can access the Website or the Izibnpl App, view information about your Account, and issue instructions regarding your Account.</p>
                    <ol start="5">
                    <li>
                    <p>Fees and Payment Terms</p>
                    </li>
                    </ol>
                    <p><strong>Merchant</strong></p>
                    <p>The Merchant agrees to pay the fees as agreed upon with the company for each BNPL transaction. These fees are incorporated by reference into this Agreement. Any value-added tax or similar taxes required by law will be in addition to the fees agreed upon.</p>
                    <p>The Merchant authorizes the company or its service provider to regularly charge the Merchant&rsquo;s checking account or other accepted payment method (&ldquo;Merchant Account&rdquo;) for all fees and amounts owed under this Agreement.</p>
                    <p>The Merchant agrees that the company may not provide prior notice of each transaction unless required by law.&nbsp;</p>
                    <p>Any fees or other amounts owed under this Agreement that are not paid within thirty (30) days may incur late fees from the date the payment was due until paid in full. The company reserves the right, in its sole discretion, to suspend or cancel services if any fees remain unpaid for more than seven (7) days after the payment due date.</p>
                    <p><strong>Customers</strong></p>
                    <p>Customers agree to pay the fees associated with each Buy Now, Pay Later (BNPL) transaction, as at when due. These fees are incorporated by reference into this Agreement.</p>
                    <p>Any applicable VAT or similar taxes required by law will be added to the fees for each BNPL transaction.</p>
                    <p>The Merchant reserves the right to modify the fees for BNPL transactions at any time. Customers will be notified of any fee changes thirty (30) days prior to the effective date of the change.</p>
                    <p>By using the BNPL service, customers authorize the company to charge their designated payment method for all fees and amounts owed under this Agreement.</p>
                    <p>Customers understand that payments for BNPL transactions may be charged to their designated payment method as soon as the transaction is initiated.</p>
                    <p>Failure to pay the fees for BNPL transactions within the specified timeframe may result in late fees and suspension or cancellation of BNPL services.&nbsp;<br /><br /></p>
                    <p>After a well-extended period of refusal to settle a default, the company reserves the right to debit any other account linked to the same User BVN.</p>
                    <ol start="6">
                    <li>
                    <p>Data Provision and Protection</p>
                    </li>
                    </ol>
                    <p>(a) Customer agrees that Izifin and the merchants process your personal data for the purpose prescribed in this Terms of Use.</p>
                    <p>(b) By Merchant: Merchants agree to use all personal information obtained from a cardholder in connection with a BNPL transaction ("Card Data") solely for the purpose of processing that transaction. Merchants are responsible for securing data in their possession or control, including through their websites. Any disclosure, transfer, or use of Card Data and other personal data in connection with the BNPL services is governed by the Data Protection Act.</p>
                    <p>(c) Izifin and Merchant agree to maintain technical and physical procedures to protect Customer&rsquo;s Data and other non-public personal information about customers from unauthorized access, accidental loss, modification, or breach. However, no security system is impenetrable, and Izifin cannot guarantee that unauthorized parties will never be able to defeat our security measures. Customer provides personal Data and other information to Izifin with the understanding that any security measures Izifin provides may not be appropriate or adequate for the merchant&rsquo;s business, and merchants agree to implement security controls that meet their specific requirements. At Izifin's sole discretion, it may take any action, including the suspension of a merchant&rsquo;s/ customer&rsquo;s account, to maintain the integrity and security of its services. Merchants waive any right to make a claim against us for losses incurred as a result of such actions.</p>
                    <p>(d) Izifin has no obligation to confirm, validate, retain, report, or otherwise provide any records of BNPL transactions or Card Data collected by us.&nbsp;</p>
                    <ol start="7">
                    <li>
                    <p>Financial and Other Information</p>
                    </li>
                    </ol>
                    <p>(a) You, as a Customer, shall promptly provide the Company with all financial information reasonably requested by or on behalf of Izifin, necessary to process your Application, conduct a periodic review of your account, and collect any amounts owed under this Agreement. This includes, but is not limited to, &ldquo;Know Your Customer&rdquo; and &ldquo;Anti-Money Laundering&rdquo; information.</p>
                    <p>(b) Izifin reserves the right to reassess your eligibility for using any of our services periodically. This may include obtaining updated business information or financial records as reasonably required to evaluate your eligibility.</p>
                    <p>(c) You, as a customer, authorize us to release your financial information to your Merchant or other institutions that reasonably request such information in connection with transactions facilitated by Izifin. We take no responsibility for how your Merchant treats this information, and you will not have any claim against us in connection with this exchange.</p>
                    <p>(d) If Izifin determines, at our sole discretion, that (i) your financial condition has deteriorated, (ii) your account is suspected of fraudulent activity of any kind, or (iii) As a Merchant, your account receives or is likely to receive a high number of customer disputes, chargebacks, or similar complaints or claims (collectively &ldquo;Risk Factors&rdquo;), we may immediately take the following action or any other actions not listed here in connection with your account and use of Izifin services:</p>
                    <ul>
                    <li>
                    <p>Refusal of New Transactions. We may temporarily or permanently cease to provide services for future transactions. Except for suspected fraudulent transactions, determined at the sole discretion of Izifin, we will continue to attempt to issue authorizations and captures for existing transactions until payment of the final installment of each such transaction. You agree to pay all fees during such a period.</p>
                    </li>
                    </ul>
                    <ol start="8">
                    <li>
                    <p>Intellectual Property</p>
                    </li>
                    </ol>
                    <p>Subject to the terms of this Agreement, Izifin hereby grants the Merchant a limited, non-exclusive, non-transferable, revocable license to access the Izibnpl Platform and use the Izibnpl Services during the Term. The Merchant acknowledges that Izifin may change or republish APIs for the Izifin Services or features of the Izibnpl Services from time to time, and that it is the Merchant&rsquo;s responsibility to ensure that requests made to the Services are compatible with the then-current APIs for the Services.</p>
                    <p>The Merchant will make logos, button links, text links, and other graphic material available to Izifin for display and use on Iziifin's website, promotional materials, social media channels, and reporting materials. For the duration of the term of this Agreement, the Merchant grants Izifin a non-exclusive, worldwide, royalty-free license to use the Merchant's intellectual property for the purpose of displaying the Promotional Materials on Izifin's website, promotional materials, social media channels, and reporting materials.</p>
                    <p>The Merchant and Customer&rsquo;s use of the Izibnpl Platform does not grant any right of ownership over any aspect of the Izibnpl Platform or over any of Izifin&rsquo;s intellectual property rights, including but not limited to patents, copyrights, trademarks, trade secrets, and other intellectual property rights (collectively &ldquo;Intellectual Property Rights''). Izifin retains all ownership rights, title, and interest throughout the world in and to all aspects of the Izibnpl Platform and the Intellectual Property Rights. The Merchant and Customer may not act in a manner that is inconsistent with, or that in any way challenges, Izifin's ownership of the Izibnpl Platform, Intellectual Property Rights, and associated registrations.</p>
                    <ol start="9">
                    <li>
                    <p>Representations and Warranties</p>
                    </li>
                    </ol>
                    <p>Merchant represents and warrants that:</p>
                    <p>(a) You are engaged in lawful business activities and are licensed to conduct your business where you operate. You further represent and warrant that all statements made in this Agreement and related documents are true, accurate, and complete.</p>
                    <p>(b)You will comply with all applicable laws, rules, and regulations related to your business. You will adhere to all material terms of our policies, procedures, and guidelines governing the BNPL Services provided to you. You may not use our platform in any way that could subject us to investigation, prosecution, or legal action. While we are not obligated to monitor your use of our services, we may do so and may prohibit any use we believe to be in violation of any rule, regulation, or policy. By agreeing to this Agreement and using our services, you confirm that you are and will remain in full compliance with the operating regulations and rules.</p>
                    <p>(c) All information provided in connection with your application is true, correct, and not misleading. You are providing information to us for your own purposes, not for another person or entity. You are solely responsible for all data, information, text, content, reports, and other materials uploaded, posted, delivered, or otherwise transmitted or stored by or on behalf of you in connection with BNPL transactions or our services, including any information provided by a Customer.&nbsp;</p>
                    <p>(d)You represent and warrant that none of the information provided will (i) violate, misappropriate, or infringe any rights of any third party, or (ii) defame or invade the privacy or publicity rights of any third party.</p>
                    <p>(e) For each BNPL transaction, you represent that it is a bona fide sale to an authorized cardholder for the amount shown on the invoice, accurately describing the goods or services sold and constituting the binding obligation of the cardholder, free from any demand, setoff, or other adverse claim. You will fulfill all of your obligations to the cardholder and resolve any Customer dispute or complaint directly with the cardholder.</p>
                    <p>(f) It is your responsibility to determine and comply with all applicable tax obligations related to your use of our services and platform.</p>
                    <p>(g) You represent and warrant that you have obtained all necessary licenses, permits, and registrations required to offer BNPL service and you will maintain such licenses, permits, and registrations in good standing and comply with all applicable laws governing them throughout the term of this Agreement.</p>
                    <p>We do not warrant the results that may be obtained from the use of our services. Your use of the services is at your own discretion and sole risk. Izifin does not warrant or guarantee the adequacy, sufficiency, accuracy, completeness, or availability of the information posted on the Platform/Application and Izifin shall have no liability for any errors, omissions, misleading statements, or interruptions therein.</p>
                    <p>To the fullest extent permitted by applicable law, Izifin, its officers, directors, employees, agents, representatives, partners, and Merchants expressly disclaim all warranties, representations, and conditions of any kind, whether express, implied, or statutory, including, but not limited to: the implied warranties or conditions of merchantability, fitness for a particular purpose and title.&nbsp;</p>
                    <p>This paragraph does not affect the Merchant&rsquo;s return policy for the products in any way.</p>
                    <p>Certain products may be subject to merchant warranties while such warranties are in effect. From time to time, information on the Izibnpl or Izifin website may contain inaccuracies or typographical errors and may not be up to date. Izifin reserves the right to correct inaccuracies, typographical errors, or omissions and to change or update information at any time without prior notice, whether before or after you placed your order.</p>
                    <p>EXCEPT FOR THE WARRANTIES EXPLICITLY SET FORTH HEREIN, OUR SERVICES ARE PROVIDED "AS-IS," AND WE DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR IMPLIED.&nbsp;</p>
                    <ol start="10">
                    <li>
                    <p>Term and Termination</p>
                    </li>
                    </ol>
                    <p>(a) This Agreement becomes effective upon your first use of the Izifin Platform and remains in effect until terminated as provided below (the &ldquo;Term&rdquo;).</p>
                    <p>(b) You may terminate this Agreement at any time with ninety (90) days' prior written notice. If you do not accept any changes made to this Agreement, you may terminate this Agreement with thirty (30) days' prior written notice.</p>
                    <p>(c) Izifin may terminate this Agreement under any of the following conditions:</p>
                    <p>(i) With ninety (90) days' prior written notice, for any reason.</p>
                    <p>(ii) With thirty (30) days' prior written notice if there is or may reasonably be an increase in Risk Factors associated with your Izifin account.</p>
                    <p>(iii) Immediately, (i) if you change the fundamental nature of your business without prior written notice to us, (ii) if you do not comply with the terms of, or fail to perform any of your obligations under, this Agreement or other agreements entered into with Izifin, (iii) if we are requested to do so by any governmental authority, (iv) if you are not in compliance with any applicable statutes, laws, rules, and regulations, or (v) if you become, or are declared to be, subject to bankruptcy, insolvency, liquidation, reorganization, court schemes, administration, or other laws generally affecting the rights of creditors.</p>
                    <p>(d) Upon termination of this Agreement, you shall remove all Izifin branding assets from your websites, and we will stop facilitating new transactions through the Izifin Platform. You will not receive a refund for any Fees paid for your use of the Izifin Services. We will continue to facilitate processing of any remaining Installments under existing transactions.</p>
                    <ol start="11">
                    <li>
                    <p>Liability</p>
                    </li>
                    </ol>
                    <p>You understand and agree that in no event shall Izifin and its associated parties be liable for any loss of profits, revenue, or data, indirect, incidental, special, or consequential damages, or damages or costs due to loss of use, business interruption, procurement of substitute goods or services. whether or not Izifin has been advised of the possibility of such damages, resulting from: (1) the use or inability to use Izifin services; (2) the cost of procurement of substitute goods or services resulting from any goods, products, data, information, or services purchased or obtained or messages received for transactions entered into through Izifin services; (3) unauthorized access to or alteration of your transmissions or data; (4) statements or conduct of any third party on Izifin platform; (5) any other matter related to Izifin services or products, whether based on warranty, copyright, contract, tort (including negligence), product liability, or any other legal theory.</p>
                    <p>You agree to indemnify and hold harmless the Izifin, its officers, directors, employees, agents, representatives, partners, from any suit, action, claim, demand, penalty, or loss (including reasonable attorneys&rsquo; fees and expenses and any amount paid in settlement to a third party) brought or asserted by any third party (including any government agency or body) arising from or in connection with: (i) Your Content, (ii) your use of or inability to use any Izifin services, (iii) your violation of the Agreement, (iv) your violation of any applicable law, rule, regulation, order, or other legal mandate, or the rights of a third party, including Registered Users, and (v) any act or omission by your agent, representative, or third-party service provider while using your Account, regardless of whether the specific use was expressly authorized by you.</p>
                    <p>The provisions in this section will survive any termination of your Account, the Agreement, and/or your access to the Services.</p>
                    <ol start="12">
                    <li>
                    <p>Dispute Resolution and the Governing Law</p>
                    </li>
                    </ol>
                    <p>This Agreement will be governed by the laws of the Federal Republic of Nigeria, exclusive of its rules governing choice of law and conflict of laws.&nbsp;&nbsp;Any disputes arising from the use of our platform or services shall be resolved through mediation and negotiation. Where a resolution cannot be reached, you shall have recourse to Arbitration in Nigeria in accordance with the provisions of the Arbitration and Mediation Act or any amendments thereto.</p>
                    <ol start="13">
                    <li>
                    <p>Severability</p>
                    </li>
                    </ol>
                    <p>If any provision of this Agreement or portion thereof is held to be unenforceable&nbsp;in whole or in part, the validity or enforceability of the other sections of these terms and conditions shall not be affected. Any headings contained in this Agreement are for informational purposes only and are not enforceable provisions of this Agreement.</p>
                    <ol start="14">
                    <li>
                    <p>Changes to Terms</p>
                    </li>
                    </ol>
                    <h5>We reserve the right to amend, modify, change or revise this Agreement at any time, in our sole and absolute discretion and without prior notice. If you do not agree to the terms of the revised Agreement, your sole and exclusive remedy is to promptly terminate your use of the Services and close your account.&nbsp;By continuing to use our platform and services, you agree to be bound by the revised Terms of Use.</h5>
                    <ol start="15">
                    <li>
                    <p>Contact Us</p>
                    </li>
                    </ol>
                    <p>You may contact us with your feedback including any complaints by emailing us at&nbsp;&hellip;<b>bnpl@izifin.com</b>&hellip;.</p>
                </div>
                {showConfirm &&
                <div>
                    <div className='flex mt-[30px]'>
                        <div>
                            <div className={`w-[20px] h-[20px] mr-[10px] cursor-pointer rounded-full
                            ${understood ? 'bg-white' : 'bg-gray-300'}
                            `}
                            onClick={()=>{
                                setUnderstood(!understood)
                            }}
                            >
                                {understood && 
                                    <img src={check} alt='checked' className='w-[100%] h-[100%]'/>
                                }
                            </div>
                        </div>
                        <div className='text-[12px] text-orange-500'>Click to agree to the terms above</div>
                    </div>
                    <div className={`${understood ? 'btn '
                        : 'p-[10px] cursor-pointer text-center !bg-gray-200 my-[20px] text-[12px] text-gray-600 rounded-[10px]'}`}
                        onClick={()=>{
                            if(understood){
                                setTermsRead(true)
                            }
                        }}
                    >
                        Continue
                    </div>
                </div>
                }
            </div>
            

            <Footnotes />
        </div >
    );
}
