import * as types from "../types"

import { loanService } from "../../services";
import StorageService from "../../utils/storageService";
import { _getTokenFromStorage } from "../../utils";

const storageService = new StorageService();



export const getSavedCookiesAsync = () =>{
    return async(dispatch) =>{
        let accessToken = _getTokenFromStorage("accessToken")
        const email = _getTokenFromStorage("email")
        const refreshToken = _getTokenFromStorage("refreshToken")
        const companyDetailsJSON = _getTokenFromStorage('companyDetails')
        const userDetailsJSON = _getTokenFromStorage('userDetails')
        let companyDetails
        let userDetails
        try {
            companyDetails = JSON.parse(companyDetailsJSON);
        } catch (e) {
            companyDetails = {}
        }

        try {
            userDetails = JSON.parse(userDetailsJSON);
        } catch (e) {
            userDetails = {}
        }

        let payload
        if(typeof accessToken !== 'undefined'){
            payload = {accessToken, email, refreshToken, companyDetails, userDetails}
            dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 0}})
            dispatch({type: types.GET_AUTH_COOKIES, payload})
        }else{
            payload = {accessToken: '', email:'', refreshToken:'', companyDetails, userDetails}
            dispatch({type: types.GET_AUTH_COOKIES, payload})
        }
    
        
    }
}

export const logoutAsync = () =>{
    storageService.clearAuthData()
    let payload = {accessToken: '', email:'', refreshToken:'',companyDetails:'', userDetails:''}
    return async(dispatch) =>{
        dispatch({type: types.GET_AUTH_COOKIES, payload})
    }
}

export const setLoanStepAsync = (payload) =>{
    return async(dispatch) =>{
        dispatch({type: types.SET_LOANSTEP, payload})
    }
}
export const clearLoanRequestAsync = () =>{
    return async(dispatch) =>{
        dispatch({type: types.CLEAR_LOAN_REQUEST})
    }
}

export const loginAsync = (payload, isError=false, successCallback) => {
    return async (dispatch) => {
        
        dispatch({ type: types.USER_LOGIN , payload: payload, isError: isError}) 
        if(!isError && typeof payload.result !== 'undefined'){
            if(payload.result.succeded){
                if(typeof payload.result.data !== "undefined"){
                    if(typeof payload.result.data.accessToken !== 'undefined'){
                        let userId = payload.phone
                        let shortCode = payload.companyShortCode
                        let validSession = false
                        //check if cookies are valid
                        // if(userId === _getTokenFromStorage('userId') && shortCode === _getTokenFromStorage(shortCode)){
                        //     validSession = true
                        // }

                        // if(!validSession){
                        //     dispatch(createEventAsync({shortCode}, 
                        //     (sessionId)=>{
                        //         storageService.addSessionData({sessionId, shortCode, userId})
                        //     },()=>{

                        //     }))
                        // }
                        dispatch(userDetailsAsync(
                            {accessToken: payload.result.data.accessToken},
                            false,
                            ()=>{
                                dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 0}})
                                successCallback?.()
                            })
                        )
                        
                    }
                }
            }
        }
    }
};

export const setErrorAsync = (payload) =>{
    return async(dispatch) =>{
        dispatch({ type: types.SET_ERROR.SUCCESS , payload: payload}) 
    }
}



export const getPendingDecisionsAsync = (payload,successCallback, errorCallback, isError=false) =>{
    return async (dispatch) => {
        dispatch({type: types.GET_PENDING_DECISIONS.REQUEST});
        try {
        //   const responseData = await loanService.getPendingDecisions(payload);
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${payload.accessToken}`);
            
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            
            };
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/pending-decisions?companyShortCode=${payload.companyShortCode}`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch({type: types.GET_PENDING_DECISIONS.SUCCESS, payload: {
                    message:'',
                    pending_decision:  result.data
                }});
                // console.log(result.data)
                successCallback?.(result.data)
            })
            .catch(error => {
                dispatch({type: types.GET_PENDING_DECISIONS.FAILURE, payload: {
                    message:'',
                    pending_decision:  error,
                    isError: true
                }});
            });
          
          
        } catch (err) {
          dispatch({type: types.GET_PENDING_DECISIONS.FAILURE, payload: err});
          errorCallback?.();
        }
      }
}

export const setLoanResponseAsync = (payload, successCallback) =>{
    return async(dispatch) => {
        // console.log(payload)
        dispatch({type: types.SET_LOAN_RESPONSE, payload});
        successCallback?.();
    }
}

// export const setCurrentDecisionAsync = (payload, successCallback, errorCallback, isError=false) =>{
//     return async(dispatch) => {
//         console.log(payload)
//         dispatch({type: types.SET_CURRENT_DECISION, payload:{
//             current_decision: payload.current_decision
//         }});
//         successCallback?.()
//     }
// }


// export const refreshTokenAsync = (payload={}, successCallback, errorCallback, isError=false) =>{
//     let auth_data = storageService.getAuthData()
    
//     return async(dispatch) =>{
//         dispatch({type: types.REFRESH_TOKEN.REQUEST});
        
//         let refreshToken = ""
//         if(typeof auth_data.refreshToken !== "undefined"){
//             refreshToken = auth_data.refreshToken
//         }else if(typeof payload.refreshToken !== "undefined"){
//             refreshToken = payload.refreshToken
//         }
//         if(refreshToken !== ""){
//             let refreshToken = auth_data.refreshToken
//             console.log('referesh',refreshToken)
//             let body = 
//             {
//                 "refreshToken": refreshToken
//             }
//             let myHeaders = new Headers();
//             myHeaders.append("Content-Type", "application/json");
//             var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             redirect: 'follow',
//             body: JSON.stringify(body)
//             };
//             fetch(`${process.env.REACT_APP_BACKEND_BASEURL}auth/refresh-token`, requestOptions)
//             .then(response => {   
//                 return response.json()
//             })
//             .then(result => {
//                 if(typeof result.data !== "undefined"){
//                     if(typeof result.data.refreshToken !== "undefined"){
//                         dispatch({ type: types.REFRESH_TOKEN.SUCCESS , payload: result, isError: false})
//                         successCallback?.()
//                     }else{
//                         dispatch(logoutAsync())  
//                     }
//                 }else{
//                     dispatch(logoutAsync())
//                 }
//             })
//             .catch(error => {
//                 dispatch({ type: types.REFRESH_TOKEN.FAILURE , payload: error, isError: true})
//             });
//         }
//     }
// }

export const setCurrentDecisionAsync = (payload, successCallback, errorCallback, isError=false) =>{
    return async (dispatch) => {
        dispatch({type: types.SET_CURRENT_DECISION, payload});
        // console.log(payload)
        successCallback?.();
      }
}

export const createDecisionAsync = (payload, successCallback, errorCallback, isError=false) =>{
    return async (dispatch) => {
        dispatch({type: types.CREATE_SESSION.REQUEST});
        try {
          const responseData = await loanService.createDecision(payload)
          dispatch({type: types.CREATE_SESSION.SUCCESS, payload: {
                message:'',
                decision: responseData.data.data
            }});
          successCallback?.(responseData.data.data);
        } catch (err) {
          dispatch({type: types.CREATE_SESSION.FAILURE, payload: err});
          errorCallback?.();
        }
      }
}
export const getEventsAsync= (payload, successCallback, errorCallback, isError=false) =>{
    return async (dispatch) => {
        dispatch({type: types.GET_EVENTS.REQUEST});
        try {
          const responseData = await loanService.getEvents(payload)
          dispatch({type: types.GET_EVENTS.SUCCESS, payload: responseData.data.Data});
          successCallback?.();
        } catch (err) {
          dispatch({type: types.GET_EVENTS.FAILURE, payload: err});
          errorCallback?.();
        }
      }
}
export const createEventAsync= (payload,successCallback, errorCallback, isError=false) =>{
    return async (dispatch) => {
        dispatch({type: types.CREATE_EVENT.REQUEST});
        try {
          const responseData = await loanService.createEvent(payload)
          dispatch({type: types.CREATE_EVENT.SUCCESS, payload: responseData.data.Data});
          successCallback?.();
        } catch (err) {
          dispatch({type: types.CREATE_EVENT.FAILURE, payload: err});
          errorCallback?.();
        }
      }
}
export const getTokenAsync = (payload, isError=false) =>{
    return async (dispatch) => {
        dispatch({ type: types.GET_TOKEN , payload: payload, isError: isError})
    }
}

export const addMonoAsync = (payload, isError=false, successCallback) =>{
    return async (dispatch) => {
        dispatch({ type: types.ADD_MONO , payload: payload, isError: isError})
        if(typeof payload.succeded !== "undefined"){
            if(payload.succeded){
                let auth_data = storageService.getAuthData()
                let session_data_updated = storageService.getSessionData()
                dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 3}})
                successCallback?.();
            }
            if(!payload.succeded && payload.message === 'Bank Already Linked'){
                successCallback?.();
            }
        }
    }
}

export const addKycAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {
        dispatch({ type: types.ADD_KYC , payload: payload.data, isError: isError})
        if(typeof payload.data !== 'undefined'){
            if(typeof payload.data.succeded !== "undefined"){
                if(payload.data.succeded){
                    dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 4}})
                    
                    let auth_data = storageService.getAuthData()
                    let session_data_updated = storageService.getSessionData()
                    // storageService.createEvent(session_data_updated.sessionId, "LoggedIn",auth_data.accessToken)
                    // storageService.getEvents(auth_data.accessToken)

                    let ss =  storageService.getEventsFromStorage()
                    successCallback?.();
                }
            }
        }
    }
}

export const finishKyc = ()  =>{
    return async (dispatch) => {
        dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 4}})
    }
}

export const requestLoanAsync = (payload, isError=false, successCallback, errorCallback)  =>{
    return async (dispatch) => {
        dispatch({ type: types.REQUEST_LOAN , payload: payload, isError: isError})
        
        if(typeof payload.succeded !== 'undefined'){
            if(typeof payload.data !== 'undefined' && payload.succeded){
                if(typeof payload.data.repaymentSchedules){
                    let auth_data = storageService.getAuthData()
                    let session_data_updated = storageService.getSessionData
                    dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 5}})
                    successCallback?.();
                }else{
                    errorCallback?.()
                }
                
            }else{
                errorCallback?.()
            }
        }else{
            errorCallback?.()
        }
        
        
    }
}


export const acceptLoanAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {
        dispatch({ type: types.ACCEPT_LOAN , payload: payload, isError: isError})
        if(typeof payload.succeded !== "undefined"){
            if(payload.succeded){
                let auth_data = storageService.getAuthData()
                let session_data_updated = storageService.getSessionData()
                // storageService.createEvent(session_data_updated.sessionId, "AcceptLoanOffer",auth_data.accessToken)
                
                dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 6}})
                successCallback?.();
            }
        }
    }
}

export const userDetailsAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {            
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${payload.accessToken}`);
            
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            
            };
            
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/me`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch({ type: types.USER_DETAILS , payload: result, isError: false})
                successCallback?.()
            })
            .catch(error => {
                dispatch({ type: types.USER_DETAILS , payload: error, isError: true})
            });

            
    }
}


export const makePreferredPaymentAsync = (payload, isError=false, successCallback) =>{
    return async (dispatch) => {   
        
        var requestOptions = {
        method: 'PUT',
        redirect: 'follow',
        headers: {
            'Authorization': `Bearer ${payload.accessToken}`,
            },
        };
      
        fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/wallet/payment-method?paymentMethod=${payload.option}&decisionId=${payload.decisionId}`, requestOptions)
        .then(response => {
            if(response.status === 401){
                dispatch(logoutAsync())
            }   
            return response.json()
        })
        .then(result => {
            dispatch({ type: types.SET_PREFERRED_PAYMENT.SUCCESS , payload: result, isError: false})
            successCallback?.()
        })
        .catch(error => {
            dispatch({ type: types.SET_PREFERRED_PAYMENT.SUCCESS , payload: error, isError: true})
        });
    }
}
export const getUserWalletAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {            
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${payload.accessToken}`);
            
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            
            };
            
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/wallet`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch({ type: types.GET_USER_WALLET , payload: result, isError: false})
                successCallback?.()
            })
            .catch(error => {
                dispatch({ type: types.GET_USER_WALLET , payload: error, isError: true})
            });

            
    }
}


export const getUserConsentAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {            
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${payload.accessToken}`);
            
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            
            };
            
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/wallet/consent-status`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch({ type: types.GET_CONSENT_STATUS , payload: result, isError: false})
                successCallback?.()
            })
            .catch(error => {
                dispatch({ type: types.GET_CONSENT_STATUS , payload: error, isError: true})
            });

            
    }
}



const convertToUTC = (localDateTime) => {
    // Create a Date object with the local date and time
    var localDate = new Date(localDateTime);
  
    // Get the timezone offset in minutes
    var timezoneOffset = localDate.getTimezoneOffset();
  
    // Convert the local time to UTC by subtracting the timezone offset
    var utcTimestamp = localDate.getTime() - (timezoneOffset * 60000);
  
    // Create a new Date object with the UTC timestamp
    var utcDate = new Date(utcTimestamp);
  
    // Return the UTC date and time in ISO format
    return utcDate.toISOString();
  }

export const createUserWalletAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {        
            
            let userDetails = 
            {
                "dob": "",
                "bvn": ""
            }
            if(typeof payload.walletData !== "undefined"){
                userDetails.bvn = payload.walletData.bvn
                userDetails.dob = convertToUTC(payload.walletData.dob)
            }
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${payload.accessToken}`);
            
            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(userDetails)
            };
            
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/wallet`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch({ type: types.CREATE_USER_WALLET , payload: result, isError: false})
                successCallback?.()
            })
            .catch(error => {
                dispatch({ type: types.CREATE_USER_WALLET , payload: error, isError: true})
            });
    }
}


export const getWalletStatusAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => {        
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${payload.accessToken}`);
            
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };
            
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/wallet/consent-status`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch({ type: types.GET_USER_WALLET_CONSENT_STATUS , payload: result, isError: false})
                successCallback?.()
            })
            .catch(error => {
                dispatch({ type: types.GET_USER_WALLET_CONSENT_STATUS , payload: error, isError: true})
            });
    }
}

export const companyDetailsAsync = (payload, isError=false, successCallback)  =>{
    return async (dispatch) => { 
        dispatch({ type: types.COMPANY_DETAILS , payload: payload, isError: isError})
        successCallback?.();
    }
}

export const clearCompanyDetailsAsync = ()  =>{
    
    return async (dispatch) => {     
        dispatch({ type: types.CLEAR_COMPANY_DETAILS })
    }
}

export const clearErrorAsync = () =>{
    return async(dispatch) =>{
        dispatch({type: types.CLEAR_ERROR})
    }
}

export const clearSuccessAsync = () =>{
    return async(dispatch) =>{
        dispatch({type: types.CLEAR_SUCCESS})
    }
}

export const updatePaymentReferenceAsync = (payload, isError, successCallback) =>{
    return async (dispatch) => {     
        dispatch({ type: types.UPDATE_PAYMENT_DETAILS, payload })
        if(!isError){
            if(typeof payload.reference !== 'undefined'){
                dispatch({type: types.CHANGE_LOANSTEP, payload:{id: 7}})
                successCallback?.()
            }    
        }
    }
}