import { getDecodedAccessToken } from "../utils";
import axios from "./axios";
import { storageService } from "./index";
// import axios from "axios";
import { _getTokenFromStorage } from "../utils";

export default class IdentityService {
    async createSession(payload){
        // return await axios.post(`auth/create-session?shortCode=${payload.shortCode}`)
    }
    async getEvents(payload){
        return await axios.get(`auth/get-events?session-id=${payload.sessionId}`)
    }
    async createEvent(payload){
        return await axios.post(`auth/create-event?sessionId=${payload.sessionId}&action=${payload.action}`)
    }
    async createDecision(payload){
        console.log('calling creating Decision ---')
        return await axios.post(`customer/decision?companyShortCode=${payload.companyShortCode}`)
       
    }
    async getPendingDecisions(){
        return await axios.get(`customer/pending-decisions`)
    }
}