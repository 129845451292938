import React from 'react'

export default function LoanSteps() {
  return (
    <div className='main'>
        <div className='izi_box bnpl_modal'>
            <div className=''>
                {/* <div className='w-[150px]'></div> */}
                <div className='w-full font-bold text-center font-gray-700 mb-[30px]'>Get started</div>
                <div className='relative'>
                    <div className='absolute bg-gray-700 w-[3px] 
                    z-1 h-full left-[20px]'></div>
                    <div className='flex'>
                        <div className='w-[50px] h-[44px] bg-green-700 z-4 relative
                        mr-[10px] mt-[-8px] rounded-[50%] border-[#f5f6f7] border-solid border-[8px] 
                        '></div>
                        <div className='w-full'>
                            <div className='font-bold text-gray-700'>Login</div>
                            <div className='font-medium text-[12px] mb-[20px]'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='w-[50px] h-[44px] bg-gray-700 z-4 relative
                        mr-[10px] mt-[-8px] rounded-[50%] border-[#f5f6f7] border-solid border-[8px] 
                        '></div>
                        <div className='w-full'>
                            <div className='font-bold text-gray-700'>Create Wallet</div>
                            <div className='font-medium text-[12px] mb-[20px]'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='w-[50px] h-[44px] bg-gray-700 z-4 relative
                        mr-[10px] mt-[-8px] rounded-[50%] border-[#f5f6f7] border-solid border-[8px] 
                        '></div>
                        <div className='w-full'>
                            <div className='font-bold text-gray-700'>Apply for BNPL</div>
                            <div  className='font-medium text-[12px] mb-[20px]'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className='font-bold text-gray-700'></div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                    <div className='font-bold text-gray-700'>Bank Statement</div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                    <div className='font-bold text-gray-700'>Verify Identity</div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div>
                    <div className='font-bold text-gray-700'>Accept BNPL Offer</div>
                    <div className='font-medium text-sm mb-[30px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}
