import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import {logoutAsync} from '../redux/actions'
import Logo from './Logo';
import {useNavigate} from 'react-router-dom'
import Logo3 from './Logo3';
import Footnotes from './Footnotes';


export default function Result({message = "", status="0"}) {
    const navigate = useNavigate()
    const [text, setText] = useState("")

    const { loanAccepted,loanResult,accessToken, } = useSelector(
        (state) => state.auth
    );
    const dispatch = useDispatch()

    useEffect(()=>{
      if(message === "" && typeof loanResult !== "undefined"){
        setText(loanResult)
      }else{
        setText(message)
      }
    },[loanResult, message])

    return (
        <>
        <div className='bnpl_modal'>
        {accessToken !== '' &&
          <div className='logout bg-gray-800'
            onClick={()=>{
              dispatch(logoutAsync())
            }}
          >Logout</div>
        }
            <Logo3 />
            <div className="form">
              {status === "0" &&
                <div className={`icon ${loanAccepted ? 'success' : 'failed'}`}></div>
              }
              {status !== "0" &&
                <div className={`icon ${status === "1" ? 'caution' : 'success'}`}></div>
              }
                <div className='body'>{text}</div>
                <div 
                  className="btn btn-primary"
                  onClick={()=>{
                    navigate('/history')
                  }}
                >Back to Home</div>
            </div>
            <Footnotes />
        </div>
        </>
    );
}
