import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Logo3 from '../../components/Logo3';
import { useSelector, useDispatch } from 'react-redux';
import {  logoutAsync, userDetailsAsync, createUserWalletAsync, makePreferredPaymentAsync} from '../../redux/actions';
import { toast } from 'react-toastify';
import back from '../../assets/back.svg'
import StorageService from '../../utils/storageService';




export default function CreateWallet() {
  const dispatch = useDispatch()
  const storageService = new StorageService()
  const {accessToken, companyDetails, userDetails,
  } = useSelector(
      (state) => state.auth
  );

  const [preferredPayment, setPreferredPayment] = useState('')
  const [data, setData] = useState({
    dob:'',
    bvn:''
  })

  const [validateData, setValidateData] = useState(
    {
      dob:false,
      bvn:false
    }
  )

  const validateNumber = (strNumber) => {
    var regExp = new RegExp("^\\d+$");
    var isValid = regExp.test(strNumber); // or just: /^\d+$/.test(strNumber);
    return isValid;
  }

  useEffect(()=>{
    let validate = {
      dob: data.dob.trim() !== "" ? true : false,
      bvn: data.bvn.trim() !== "" && data.bvn.trim().length === 11 ? true : false
    }
    console.log(validate)
    setValidateData(validate)
  },[data])
  const navigate = useNavigate()

  useEffect(()=>{
    console.log(data)
  },[data])

  useEffect(()=>{
    if(typeof userDetails.paymentMethod !== "undefined"){
      setPreferredPayment(userDetails.paymentMethod)
    }
  },[userDetails])
    return (
    <div className='main'>
        <div className='izi_box bnpl_modal'>
            <Logo3 />
            {accessToken !== '' && typeof userDetails !== 'undefined' &&
                <div className='logout bg-gray-800'
                    onClick={()=>{
                    dispatch(logoutAsync())
                    }}
                >Logout</div>
            }
                <div className='flex flex-row gap-[20px] py-[5px] mt-[20px] cursor-pointer'>
                    <div className='text-xs 
                    bg-gray-200 py-[4px] px-[10px]
                    flex flex-row rounded-[13px]'
                        onClick={()=>navigate('/payment-options')}
                    >
                        <img src={back} className='w-[15px] h-[15px]'/>
                        <div className=''>Back</div>
                    </div>
                    <div className='text-xs pt-[3px]
                    font-bold
                    '>{`Create wallet`}</div>
                </div>
                <div className='w-full mt-[20px]'>

                      {/* <div className='fieldset py-[5px] w-100'>
                        <div className='label text-xs'>Gender</div>
                        {!validateData.gender &&
                            <div className='sidenote'>Enter a valid gender</div>
                        }
                        <select className='h-[40px]'
                        onChange={(e)=>setData({...data, gender: e.target.value})}
                        >
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </select>
                      </div> */}

                      <div className='fieldset py-[5px] w-100'>
                        <div className='label text-xs'>Date of birth</div>
                        {!validateData.dob &&
                            <div className='sidenote'>Enter a valid date of birth</div>
                        }
                        <input className='h-[40px]' type='date'
                          onChange={(e)=>{
                            let dob = e.target.value
                            dob = dob.toString() + ' 00:00:00.565183+00'
                            setData({...data, dob})}
                          }
                        />
                      </div>
                      <div className='fieldset py-[5px] w-100'>
                        <div className='label text-xs'>BVN</div>
                        {!validateData.bvn &&
                            <div className='sidenote'>Enter a valid bvn</div>
                        }
                        <input className='h-[40px]'
                        value={data.bvn}
                        onChange={(e)=>{
                          let bvn = e.target.value
                          if(bvn.length <= 11 && validateNumber(bvn)){
                            setData({...data, bvn})
                          }
                        }
                        }
                        />
                      </div>
                      <div className={`
                      w-full text-center text-sm p-[10px] cursor-pointer rounded-[5px] py-[5px] text-white
                      ${validateData.bvn && validateData.dob ?
                      'bg-[#2c3e50]'
                      :'bg-gray-200'
                      }
                      `}
                      onClick={()=>{
                        if(validateData.bvn && validateData.dob ){
                          let auth_data = storageService.getAuthData()
                          console.log(auth_data)
                          if(typeof auth_data.accessToken !== "undefined" && 
                          typeof auth_data.phone !== "undefined" 
                          && typeof userDetails !== "undefined"){
                            dispatch(createUserWalletAsync(
                              {
                                accessToken: auth_data.accessToken,
                                phone: auth_data.phone,
                                userDetails: userDetails,
                                walletData: data
                              },
                              false,
                              ()=>{
                                if(preferredPayment === 0){
                                    if(typeof auth_data.accessToken !== "undefined"){
                                        dispatch(makePreferredPaymentAsync({
                                            option: 2,
                                            accessToken: auth_data.accessToken},
                                            false,
                                            ()=>{
                                              navigate('/payment-options') 
                                            }))
                                    }
                                
                                }
                                navigate('/payment-options')
                              }
                            ))
                          }
                        }else{
                          toast.error("Fill a compulsory fields", {
                            position: "top-right",
                            autoClose:false
                          })

                        }
                      }}
                      >Create Wallet</div>
                </div>
        </div>
    </div>
  )
}
