
import './assets/global.css';

import { useEffect, useState } from 'react';
import AllRoutes from './AllRoutes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {clearErrorAsync, clearSuccessAsync} from './redux/actions'


function App() { 
  const {errorMessage, successMessage, infoMessage} = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch()

  const notify = () => toast("Wow so easy!");
 


  useEffect(()=>{
    if(errorMessage !== '' && errorMessage !== "undefined" && typeof errorMessage !== "undefined"){
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: false,
          onClose: () => {
            dispatch(clearErrorAsync())
          }
        })
    }
  },[errorMessage])

  useEffect(()=>{
    if(successMessage !== ''){
        toast.success(successMessage, {
          position: "top-right",
          autoClose: 5000,
          onClose: () => {
            dispatch(clearSuccessAsync())
          }
        })
    }
  },[successMessage])

  useEffect(()=>{
    if(infoMessage !== ''){
        toast.success(infoMessage, {
          position: "top-right",
          autoClose: 5000,
          onClose: () => {
            dispatch(clearErrorAsync())
          }
        })
    }
  },[infoMessage])

  return (
    <div className="App">
        <ToastContainer />
        <AllRoutes />
        
    </div>
  );
}

export default App;
