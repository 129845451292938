import React,{useState} from 'react'
import { useMono } from 'react-mono-js';
import { useDispatch, useSelector } from 'react-redux';
import { addMonoAsync, getPendingDecisionsAsync, setCurrentDecisionAsync } from '../../redux/actions';
import {useNavigate} from 'react-router-dom'
import {logoutAsync} from '../../redux/actions'
import Logo3 from '../Logo3';
import Footnotes from '../Footnotes'
import TermsOfUse from '../TermsOfUse'
import cancel from '../../assets/cancel-35.svg'

function Mono() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [statementType, setStatementType] = useState('mono')
    const [showTerms, setShowTerms] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleMono = useMono({ public_key: process.env.REACT_APP_MONO_KEY })
    const {accessToken, decisionId, pending_decision, companyDetails} = useSelector(
      (state) => state.auth
    );
     
    const refreshPending = ()=>{
      dispatch(
      getPendingDecisionsAsync({accessToken, companyShortCode: companyDetails.shortCode},
      ()=>{
        if(decisionId !== null && pending_decision !== null){
          if(typeof pending_decision[0] !== 'undefined'){
            let m = pending_decision.filter((pd)=>{
              if(pd.id === decisionId){
                return true
              }else{
                return false
              }
            })
            console.log("here6", m[0])
            dispatch(setCurrentDecisionAsync(
              {current_decision: m[0]}
            ))
          }
        }
      })
      )
    }  
  
    const addMono = (monoCode) =>{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            }
          };
          setLoading(true)
          
          fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/update-bank-link?monoCode=${monoCode}&decisionId=${decisionId}`, requestOptions)
            .then(response => {
              let jsonResponse = response.json()
              if(response.status === 401){
                dispatch(logoutAsync())
              }
              return jsonResponse
            })
            .then(result => {
              console.log(result)
              if(typeof result)
              setTimeout(() => {
                setLoading(false)
                refreshPending()
                // dispatch(addMonoAsync(result, false,
                //   refreshPending()
                // ))
              }, 4000)
            })
            .catch(error => 
                {
                    if (error.status) {
                      // Handle the status code
                      console.error(`Error status code: ${error.status}`);
                      console.log(error.details)
                      if(error.status === 400){
                        console.log(error.message)
                        refreshPending()
                      }
                    } else {
                        // Handle other errors
                        console.error('Fetch error:', error);
                    }
                    setLoading(false)
                    dispatch(addMonoAsync(error, true, ()=>{})
                );
              }
            )
      }
    
    const proceedToMono = () => {
        setProcessing(false)
        handleMono({
            onClose: () => {

            },
            onSuccess: (response) => {
              if(typeof response.code !== 'undefined'){
                addMono(response.code)
              }
            }
        })
    }
  return (
    <>
    <div className='bnpl_modal relative !min-h-[600px]'>
        {accessToken !== '' && 
          <div className='logout bg-gray-800'
            onClick={()=>{
              dispatch(logoutAsync())
            }}
          >Logout</div>
        }
        {showTerms &&
        <div className='absolute bg-white w-[80%] ml-[5%] mt-[30px] h-[400px] rounded-[10px]'>
          <div className='absolute w-[20px] right-[10px] mt-[10px]
          h-[20px] rounded-full cursor-pointer'
          onClick={()=>{
            setShowTerms(false)
          }}
          >
            <img src={cancel} alt="cancel-icon"/>
          </div>
          <TermsOfUse />
        </div>
        }
        <div className='flex !gap-4 h-[25px] mt-[20px] mb-[40px]'>
            <div className='profile_name'
            onClick={()=>{
            navigate('/history')
            }}
            >
              <div className='icon_ back'></div>
            </div>
            <div className='leading-[40px] font-bold'>Bank Statement</div>
        </div>
        
        {/* {!submittingDocument && fileError === "" &&
        <div className={`toggle_options file_option_tab ${loading ? 'fade' : ''}`}>
            
            <div className={`toggle_btn ${statementType === "mono" ? "active" : ""}`}
              onClick={() => {
                if(!loading){
                  setStatementType('mono')
                }
              }}
            >Login to Bank</div>

            <div className={`toggle_btn ${statementType === "upload" ? "active" : ""}`}
              onClick={() => {
                setStatementType('upload')
              }}>Upload Bank Statement</div>
        </div>
        } */}

        
        <Logo3 />
        {!loading &&
        <div className='white_box small_box modal_content'>
            <div className='title'>Bank Statement</div>
            <div className='modal_body'>Your financial records are necessary to evaluate your eligibility for BNPL.</div>
            <div className='btn btn-primary'>
              <div className='text' 
              onClick={() => {
                proceedToMono() // comment this out to test without mono
              }}
              >Add Bank Statement</div>        
            </div>
        </div>
        }
        {loading &&
              <div className='modal_body'>
              ... Linking Bank
              <br /><br />
              </div>
          }
        <div className='absolute bottom-[20px] w-[100%]'>
          <Footnotes />
        </div>
    </div>
    </>
  )
}

export default Mono