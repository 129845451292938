import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactCodeInput from 'react-code-input'
import StorageService from '../utils/storageService';
import { useDispatch, useSelector } from 'react-redux'
import { loginAsync, getTokenAsync, companyDetailsAsync, clearCompanyDetailsAsync, 
    clearLoanRequestAsync, userDetailsAsync } from '../redux/actions/auth.action'
import { logoutAsync } from '../redux/actions/auth.action';
import { useNavigate } from 'react-router-dom';
import {validEmail, validPhone, validText} from '../utils'
import Logo from './Logo'
import { toast } from 'react-toastify';
import { setErrorAsync } from '../redux/actions/auth.action';
import loadingIcon from '../assets/loading.gif'
import cautionIcon from '../assets/caution.svg'
// import Preloader from './Preloader';
import Footnotes from './Footnotes';

export default function Login(){
    const navigate = useNavigate()
    // const [tokenCounter, setTokenCounter] = useState(0)
    const tokenCountdown = 90
    const [timeLeft, setTimeLeft] = useState(tokenCountdown); // 120 minutes in seconds
    const [showResetButton, setShowResetButton] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search)
    const company = queryParameters.get("c")
    const storageService = new StorageService()
    const dispatch = useDispatch()
    // const [bvn, setBvn] = useState('')
    const [phone, setPhone] = useState('')
    const [companyShortCode, setCompanyShortCode] = useState('')
    const [checkingCompany, setCheckingCompany] = useState(false)
    const [companyChecked, setCompanyChecked] = useState(false)
    const [companyInvalid, setCompanyInvalid] = useState(true)
    const [phoneValid, setPhoneValid] = useState(false)
    const {accessToken, userExists, tokenSent, login_data, companyDetails,
    } = useSelector(
        (state) => state.auth
      );

    
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [showError, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [confirmLink, setConfirmLink] = useState(false)

    const [data, setData] = useState(
        {
            "firstName": "",
            "lastName": "", 
            "middleName": "",
            "businessName": "",
            "phoneNumber": "",
            "email":"",
            "address":""
        }
    )
    const [validateData, setValidateData] = useState(
        {
            "firstName": false,
            "lastName": false,
            "middleName": false,
            "phoneNumber": false,
            "email":"",
            "address":false,
            "otp":false
        }
    )
    const [allValid, setAllValid] = useState(false)

    
    const getToken = (phone) =>{
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
          };
          setLoading(true)

          
          fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/login?phoneNumber=${encodeURIComponent("+"+phone.toString().trim())}`, requestOptions)
            .then(response => {
                setTimeLeft(tokenCountdown)
                setShowResetButton(false)
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                setLoading(false)
                
                dispatch(getTokenAsync(result))
            })
            .catch(error => 
                {
                    setLoading(false)
                    dispatch(getTokenAsync(error, true))
                });
    }
  

    const getCompanyDetails = (companyShortCode) =>{
            setConfirmLink(false)
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            
            };
            setCheckingCompany(true)
            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/company/${companyShortCode.toString().trim()}`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(logoutAsync())
                }   
                return response.json()
            })
            .then(result => {
                dispatch(companyDetailsAsync(result, false, 
                    ()=>{
                        setCheckingCompany(false)
                    }
                ))
                if(typeof result.succeded !== 'undefined'){
                    if(!result.succeded){
                        setErrorText(result.message)
                        setConfirmLink(true)
                    }
                }
            })
            .catch(error => {
                dispatch(companyDetailsAsync(error, true, 
                    ()=>{
                        setCheckingCompany(false)
                    }
                ))
            });
    }


    const confirmOtp = (phone, token) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = ''

        if(!userExists){
            raw = JSON.stringify({
                "otp": token,
                "firstName": data.firstName,
                "lastName": data.lastName,
                "middleName": data.middleName,
                "businessName": data.businessName,
                "email":data.email,
                "address": data.address,
                "phoneNumber": "+"+phone,
            });
        }

        if(userExists){
            raw = JSON.stringify({
            "otp": token.toString().trim(),
            "phoneNumber": "+"+phone.toString().trim(),
            });
        }

        setLoading(true)
            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/token`, requestOptions)
        .then(response => {
            if(response.status === 401){
                dispatch(logoutAsync())
            }   
            return response.json()
        })
        .then(result => {
            setLoading(false)
            if(result.succeded === false){
                setError(true)
                setErrorText(result.message)
            } 
            dispatch(
                loginAsync({result,companyShortCode,phone}, false, async ()=>{
                    if(typeof result.data.accessToken !== "undefined"){
                        dispatch(userDetailsAsync({accessToken: result.data.accessToken}))
                    }
                    storageService.savePhone("+"+phone.toString().trim())
                    navigate('/history')
                })
            );
        })
        .catch(error => {
            dispatch(loginAsync(error, true, 
                ()=>{
                    navigate('/history')
                }
                ))
        });
    }

    useEffect(()=>{
        let v = {...validateData
        , firstName: validText(data.firstName.toString().trim())
        , lastName : validText(data.lastName.toString().trim())
        , middleName : validText(data.middleName.toString().trim())
        , phoneNumber : validPhone(phone.toString().trim())
        , email : validEmail(data.email.toString().trim())
        , address : validText(data.address.toString().trim())
        , token : token.length === 6 ? true : false
        }
        setValidateData(v)
    },[data, token])

    useEffect(()=>{
        setAllValid(
            validateData.firstName &&
            validateData.lastName &&
            validateData.middleName &&
            validateData.phoneNumber &&
            validateData.email &&
            validateData.address &&
            validateData.token
        )
    },[validateData])

    useEffect(()=>{
        if(typeof companyShortCode !== 'undefined'){
            if(companyShortCode.length === 9){
                getCompanyDetails(companyShortCode)
            }
        }
    },[companyShortCode])

    useEffect(() => {
        
        if (timeLeft > 0) {
          const timerId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
          }, 1000);
          return () => clearInterval(timerId); // Clear interval on component unmount
        } else {
          setShowResetButton(true); // Show reset button when countdown is over
        }
      }, [timeLeft]);

    

    useEffect(()=>{
        dispatch(clearLoanRequestAsync())
    },[])

    useEffect(()=>{
        if(errorText !== ''){
            toast.error(errorText, {
              position: "top-right",
              autoClose:false,
              onClose: () => {
                setErrorText('')
              }
            })
        }
    },[errorText])

    useEffect(()=>{
        if(typeof company !== 'undefined' && company !== null){
            if(company.length === 9){
                setCompanyShortCode(company)
                setCompanyInvalid(false)
            }else{
                setCompanyInvalid(true)
            }
        }else{
            setCompanyInvalid(true)
        }
    },[company])


    useEffect(()=>{
        if(typeof companyDetails !== 'undefined' && JSON.stringify(companyDetails) !== '{}'){
            if(typeof companyDetails.name !== 'undefined'){
                if(companyDetails.name === ''){
                    setCompanyChecked(false)
                }else{
                    setCompanyChecked(true)
                    // setCompanyShortCode(companyDetails.shortCode)
                }
            }else{
                setCompanyChecked(false)
            }
        }else{
            setCompanyChecked(false)
        }
    },[companyDetails])

    useEffect(()=>{
        setPhoneValid(validPhone(phone))
    },[phone])
    return (
        <>
        <div className='bnpl_modal'>
            

            <Logo />
            <div className="form">
            
            {!tokenSent &&
                <div>
                    {/* <label>Company Shortcode</label> */}
                    {/* <div className='sidenote'>Unique shortcode (9 characters) of company offering BNPL</div> */}
                        {/* <div className='fieldgroup'>
                        <input 
                        value={companyShortCode}
                        onChange={(e) => {
                            if(companyShortCode.length < 9){
                                setCompanyShortCode(e.target.value)
                            }
                        }} 
                        disabled={checkingCompany || companyChecked}
                        placeholder='Enter company shortcode'
                        ></input>
                        {(companyChecked || companyShortCode.length >= 9) &&
                        <div className='icon_ restart'
                        onClick={()=>{
                            setCompanyShortCode('')
                            setCompanyChecked(false)
                            setCheckingCompany(false)
                            dispatch(clearCompanyDetailsAsync())
                            
                        }}
                        ></div>
                        }
                        </div> */}
                        {errorText}
                        {confirmLink &&
                            <div className='small_note text-red-400'>Kindly, use the link provided by company.</div>
                        }
                        {companyInvalid &&
                            <div className='small_note text-[#2c3e50] flex flex-row gap-[10px]'>
                                <img src={cautionIcon} className='w-[15px] h-[15px] rounded-[5px]' />
                                Use exact link provided by company</div>
                        }
                        {checkingCompany &&
                            <div className='small_note text-[#2c3e50] flex flex-row gap-[10px]'>
                                <img src={loadingIcon} className='w-[15px] h-[15px] rounded-[5px]' />
                                ...Getting company details</div>
                        }
                        
                        {companyChecked &&
                            <div className='side_note'>{companyDetails.name}</div>
                        }
                    <label className={`${!companyChecked ? 'fade' : ''}`}>Phone Number</label>
                    {companyChecked &&
                    <PhoneInput
                        country={'ng'}
                        onlyCountries={['ng']}
                        onChange={thisphone => {
                            setPhone(thisphone)
                        }}
                    />
                    }
                    {!companyChecked &&
                        <input placeholder='Phone No' disabled className={`${!companyChecked ? 'fade' : ''}`} />
                    
                    }
                    
                    {!loading && 
                    <div className={`btn btn-primary ${!companyChecked || !phoneValid ? 'fade' : ''}`}
                    onClick={()=>{
                        if(companyChecked && phoneValid){
                            getToken(phone)
                        }
                    }}
                    >Request Token</div>
                    }
                    {loading && 
                        <div className='loading_text'>...Sending Token</div>
                    }
                </div>
            }
            {tokenSent &&
                <div>
                    {!userExists &&
                    <>
                        <label>Firstname</label>
                        {!validateData.firstName &&
                            <div className='sidenote'>Enter a valid name</div>
                        }
                        <input 
                            className='h-[50px]'
                            onChange={(e)=>{
                                setData({...data, firstName: e.target.value.toString().trim()})
                            }}
                        />

                        <label>Lastname</label>
                        {!validateData.lastName &&
                            <div className='sidenote'>Enter a valid name</div>
                        }
                        <input 
                            className='h-[50px]'
                            onChange={(e)=>{
                                setData({...data, lastName: e.target.value.toString().trim()})
                            }}
                        />

                        {/* <div className='label'> */}
                            <label>Middle Name</label>
                        {/* </div> */}
                        {!validateData.middleName &&
                            <div className='sidenote'>Enter a valid name</div>
                        }
                        <input 
                            className='h-[50px]'
                            onChange={(e)=>{
                                setData({...data, middleName: e.target.value.toString().trim()})
                            }}
                        />

                        {/* <div className='label'> */}
                        <label>Address</label>
                        {/* </div> */}
                        {!validateData.address &&
                            <div className='sidenote'>Enter a valid address</div>
                        }
                        <textarea 
                            onChange={(e)=>{
                                setData({...data, address: e.target.value.toString().trim()})
                            }}
                        ></textarea>


                        <label>Business Name (if applicable)</label>
                        <input 
                            className='h-[50px]'
                            onChange={(e)=>{
                                setData({...data, businessName: e.target.value.toString().trim()})
                            }}
                        />
                        
                        <label>Email</label>
                        
                        {!validateData.email &&
                            <div className='sidenote'>Enter a valid email attached to bank.</div>
                        }
                        <input 
                            className='h-[50px]'
                            onChange={(e)=>{
                                setData({...data, email: e.target.value.toString().trim()})
                            }}
                        />
                        
                        {/* <label>BVN</label> */}
                        
                        {/* {!validateData.bvn &&
                            <div className='sidenote'>Enter a valid bvn</div>
                        } */}
                        {/* <input 
                            onChange={(e)=>{
                                let v = e.target.value
                                if(v.length <= 11 && !isNaN(v)){
                                    setBvn(v)
                                    setData({...data, bvn: v})
                                }
                            }}
                            value={bvn}
                        /> */}
                    </>
                    }
                    <label>Token</label>
                    {!validateData.token && 
                        <div className='sidenote'>Enter 6 digit code sent your phone</div>
                    }
                    <ReactCodeInput type='number' fields={6} 
                    onChange={(value)=>{
                        setToken(value)
                    }} />

                    
                    <div className="mt-[30px]">
                    
                    
                    {!showResetButton &&
                    <span>Resend Token in 
                    <span className='ml-[10px] font-bold '>{timeLeft}s</span>   
                    </span>          
                    }
                    {showResetButton &&
                    <>
                    <span className={`font-bold cursor-pointer ${!companyChecked || !phoneValid ? 'fade' : ''}`}
                    onClick={()=>{
                        if(companyChecked && phoneValid){
                            getToken(phone)
                        }
                    }}
                    >Resend Token</span>
                    {loading && 
                        <span className='loading_text'>...Sending Token</span>
                    }
                    </>}
                    </div>

                    <div className={`btn
                        ${(validateData.token && userExists) ||
                           (allValid && !userExists)  
                            ? 'btn-primary' : 'btn-disabled'}
                    `}
                    onClick={()=>{
                        if((validateData.token && userExists) ||
                        (allValid && !userExists)){
                        confirmOtp(phone, token)
                        }else{
                            dispatch(
                                setErrorAsync("Fill all compulsory fields")
                            )
                        }
                    }}
                    >Submit</div>


                    {loading && 
                    <div className='loading_text'>...Confirming OTP</div>
                    }
                </div>
            }
                <Footnotes />
            </div>
            </div>
        </>
    );
}
