import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Kyc from '../components/Kyc';
import Mono from '../components/Mono';
import Pay from '../components/Pay';
import RequestLoan from '../components/RequestLoan';
import AcceptLoan from '../components/AcceptLoan';
import Result from '../components/Result';
import { setLoanStepAsync, userDetailsAsync, getPendingDecisionsAsync, setCurrentDecisionAsync, makePreferredPaymentAsync } from '../redux/actions';
import { convertToMoney } from '../utils';
import StorageService from '../utils/storageService';
import LinkPayment from '../components/LinkPayment';
import AddPaymentOptions from './payment/AddPaymentOptions';
import DataUseTerms from '../components/DataUseTerms';

export default function ApplyLoan() {
    const navigate = useNavigate()
    const storageService = new StorageService()
    const [termsRead, setTermsRead] = useState(false)
    const [currentLoanStep, setCurrentLoanStep] = useState(0)
    const { loanSteps, bnplStepsDone, companyDetails, decisionId, accessToken, pendingIndex,loanResponse,
      userDetails, pending_decision, decision, creating_decision, current_decision, loanResult, loanAccepted, walletDetails
    } = useSelector(
    (state) => state.auth);
    const [loading, setLoading] = useState(true)
    const [preferredPayment, setPreferredPayment] = useState('')
    const [selectedPreferred, setSelectedPreferred] = useState(false) 
    const [pendingDecisionIndex, setPendingDecisionIndex] = useState(0)

    const [walletCreated, setWalletCreated] = useState(false)
    const [bankLinked, setbankLinked] = useState(true)

    const dispatch = useDispatch()
    // const [closeModal, setCloseModal] = useState(false)

    useEffect(()=>{
      if(typeof walletDetails !== "undefined"){
          if(typeof walletDetails.accounts !== "undefined"){
              setWalletCreated(true)
          }else{
              setWalletCreated(false)
          }
      }
    },[walletDetails])

    useEffect(()=>{
      if(typeof userDetails.paymentDetailsVerified !== "undefined"){
        setbankLinked(userDetails.paymentDetailsVerified)
      }
    },[userDetails])

    useEffect(()=>{
      if(loanSteps[currentLoanStep] === "INITIAL_PAYMENT" && Number(preferredPayment) !== 0){ 
				setCurrentLoanStep(1)
      }
    },[currentLoanStep, preferredPayment])

    useEffect(()=>{
      let auth_data = storageService.getAuthData()
      if(typeof auth_data.accessToken !== "undefined"){
        dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))
      }
    },[])

    // useEffect(()=>{
    //   if(typeof userDetails !== "undefined"){
    //     if(typeof userDetails.paymentMethod !== "undefined"){
    //       setPreferredPayment(userDetails.paymentMethod)
    //     }
    //   }
    // },[userDetails])

    

    useEffect(()=>{
      if(typeof current_decision === "undefined" || current_decision === null){
        navigate('/');
      }
    },[])

  

  useEffect(()=>{
    
    if(typeof pending_decision !== 'undefined' 
    && pending_decision !== null 
    && JSON.stringify(pending_decision) !== "[]"
    && pendingIndex !== null
    ){
      let pd_index = pendingIndex
      if(pendingIndex >= pending_decision.length)
      {
        pd_index = pendingIndex - 1 
      }
      if(typeof pending_decision[pd_index] !== 'undefined'){ 
        dispatch(setCurrentDecisionAsync({current_decision:pending_decision[pd_index]}))
      }
    }
  },[pending_decision, pendingIndex])

  useEffect(()=>{
    if(typeof current_decision !== "undefined" && current_decision !== null ){
      if(current_decision.isBankLinked){
        setTermsRead(true)
      }
      if(typeof current_decision.paymentMethod !== "undefined"){
        if(current_decision.paymentMethod !== 0){
          setPreferredPayment(current_decision.paymentMethod)
          setSelectedPreferred(true)
          setLoading(false)
        }else{
          if(typeof companyDetails.allowedPaymentMethods !== "undefined"){
            // let auto_add_payment = false
            if(companyDetails.allowedPaymentMethods.length === 1){
              console.log(companyDetails.allowedPaymentMethods[0], walletCreated ? 'Wallet Created': 'Walllet not created')
              if( (companyDetails.allowedPaymentMethods[0] === 'Wallet' && walletCreated)
              || (companyDetails.allowedPaymentMethods[0] === 'Card' && bankLinked )
              ){
                dispatch(makePreferredPaymentAsync({
                  decisionId:current_decision.id,
                  option: companyDetails.allowedPaymentMethods[0],
                  accessToken: accessToken
                },
                  false,
                  ()=>{
                        
                          setPreferredPayment(companyDetails.allowedPaymentMethods[0])
                          setSelectedPreferred(true)
                          setLoading(false)
                          // dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
                      
                  }))
                // auto_add_payment = true
              }else{
                setLoading(false)
              }
            }else{
              setLoading(false)
            }
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_decision, companyDetails, accessToken])

  

  

  return (
    <div className='main'>
    {!termsRead &&
      <DataUseTerms 
        setTermsRead = {setTermsRead}
      />
    }
    {termsRead &&
      <div>
      {(creating_decision || loading) &&
        <div className='main'>
          <div className='izi_box bnpl_modal'>
            Starting Decision...
          </div>
        </div>
      }
      {!creating_decision && current_decision !== null && !loading &&
        <div>
          {(typeof currentLoanStep !== 'undefined' && typeof loanSteps !== 'undefined') &&
              <>
              <hr/>
                  {(Number(currentLoanStep) >= 0 && !isNaN(currentLoanStep)) &&
                  <>    
                    { loanResult === "" &&
                    <>
                      {!selectedPreferred &&
                        <>
                          <AddPaymentOptions 
                              current_decision={
                                  current_decision
                              }
                              setSelectedPreferred={setSelectedPreferred}
                              setPreferredPayment={setPreferredPayment}
                              preferredPayment={preferredPayment}
                          />
                        </>
                      }
                      {preferredPayment !== '' && selectedPreferred && 
                        <>
                          {!(current_decision.isMonoSetupCompleted) 
                          && <Mono /> }
                          
                          {current_decision.isMonoSetupCompleted 
                          && !(current_decision.isKYCInformationSubmitted) 
                          && <Kyc/> }
                          
                          {current_decision.isMonoSetupCompleted 
                          && current_decision.isKYCInformationSubmitted 
                          && !(current_decision.isDecisioningCompleted) 
                          && typeof current_decision.repaymentSchedules !== "undefined" &&
                          <div>
                            {current_decision.status === 0 &&
                              <RequestLoan /> 
                            }
                            {current_decision.status === 1 &&
                              <Result message="BNPL decision pending, Kindly check back for status." status="1"/> 
                            }
                            {current_decision.repaymentSchedules.length > 0 && current_decision.status === 2 &&
                              <AcceptLoan />  
                            }
                          </div>
                          }
                        </>
                        }
                        

                    </>
                    }
                  
                    { loanResult !== ""
                    && <Result /> }

                  </>
                  }
              </> 
          }

          {/* {companyDetails.serviceFee > 0 && !closeModal && 
            <div className='modal_bg'>
              <div className='message'>
                <div className='icon_ close' onClick={()=>{setCloseModal(true)}}></div>
                <div className='title_'>Service Fee</div>
                <div className='modal_body'>Please note that a Service fee of <strong>{convertToMoney(companyDetails.serviceFee)}</strong> will be charged from your account on completion of this BNPL process.</div>
              </div>
            </div>
          } */}
        </div>
      }
    </div>}

    </div>
  )
}