import React, { useRef, useCallback, useState } from 'react'
import { Camera } from "react-camera-pro";
import { useDropzone } from 'react-dropzone'

function Kyc({
  idType, setIdType,
  idFile, setIdFile,
  idSrc, setIdSrc,
  next, vnin, setVnin,
  uploadType,
  setUploadType,
  loading
}) {

  // const [idType, setIdType] = useState("")

  const allowed_cards = [
    {
      id: "nin_slip",
      name: "NIN Slip",
      url: "nin"
    },
    {
      id: "drivers_licence",
      name: "Driver's Licence",
      url: "drivers-licence"
    },
    {
      id: "international_passport",
      name: "International Passport",
      url: "passport"
    }
  ]

  // const captureId = () => {
  //   faceMatching()
  // }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files

    if (acceptedFiles) {
      // console.log(acceptedFiles[0])
      let img = acceptedFiles[0];
      setIdSrc(URL.createObjectURL(img))
      setIdFile(img)
      // console.log(idSrc)
    }
  }, [])


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const camera = useRef(null);
  const captureImage = async () => {
    if (idSrc === "" || idSrc === null) {
      await setIdSrc(camera.current.takePhoto());

    } else {
      const file = await fetch(idSrc).then(r => r.blob()).then(blobFile => new File([blobFile], 'id.jpg', { type: blobFile.type }))
      var photo_file = new File([idSrc], "id.jpg", { type: "image/jpeg" })
      await setIdFile(file)
      next()
    }
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  return (
    <>
      <div className='white_box'>
        <div className="title">Your ID Document</div>
        <div className="select_id_type">
          <select
            onChange={(e) => {
              setIdType(e.target.value)
            }}
            className="id_select"
            defaultValue={idType}
            disabled = {loading}
          >
            <option value="" disabled > -- Select ID Type --</option>
            {allowed_cards.map((this_card, index) => {
              return (
                <option key={this_card.id + index} value={this_card.url}>{this_card.name}</option>
              )
            })}
          </select>
        </div>
        {idType !== "" &&
          < div className={`toggle_options file_option_tab ${loading ? 'fade' : ''}`}>
            <div className={`toggle_btn ${uploadType !== "camera" ? "active" : ""}`}
              onClick={() => {
                if(!loading){
                  setIdSrc(null)
                  setIdFile(null)
                  setUploadType('upload')
                }
              }}>Upload</div>
            <div className={`toggle_btn ${uploadType === "camera" ? "active" : ""}`}
              onClick={() => {
                if(!loading){
                  setIdSrc(null)
                  setIdFile(null)
                  setUploadType('camera')
                }
              }}
            >Camera</div>
          </div>
        }
        {
          idType !== "" && uploadType !== "camera" && (idSrc === null || idSrc === "") &&
          <div>
            <div>
              {/* <input type="file" className='form-control' onChange={(e)=>uploadFile(e)} /> */}
              <div {...getRootProps()}

                className="id_image_box upload_box document-div"
              >
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <div className='title'>Drop the files here ...</div> :
                    <div>
                      <div className='title'>Drop the id here ...</div>
                      <div className='btn btn-primary'>
                        {/* <div className='icon plus'></div> */}
                        <div className='text'>Upload File</div>
                      </div>
                    </div>

                }
              </div>
            </div>

          </div>
        }

        {
          idType !== "" && uploadType === "camera" && (idSrc === null || idSrc === "") &&
          <div>
            {/* <div className='label'>Capture ID</div> */}
            <div className='id_image_box document-div'>
              <div className='camera_box' >
                {(idSrc === null || idSrc === "") &&
                  <span>
                    <Camera ref={camera}
                      facingMode='environment'
                    />

                    <div className='switch_camera'
                      onClick={() => camera.current.switchCamera()}
                    ></div>
                  </span>
                }
              </div>
            </div>
          </div>
        }

        {(idSrc !== null && idSrc !== "") &&
          <div className={`id_image_box ${loading ? 'fade' : ''}`}>
            <div className='camera_box'>
              <div className='cancel' onClick={() => setIdSrc("")}></div>
              <img src={idSrc} width="100%" style={{ objectFit: "cover" }} />
            </div>
          </div>
        }

        {(!loading && idType !== "" && idType !== null) && (idSrc === null || idSrc === "") && uploadType === "camera" &&
          <div className='bottom_'>
            <div className={`btn ${idSrc !== null && idSrc !== "" ? "btn-primary" : "btn-primary"}`}
              onClick={() => { captureImage() }}
            >
              {(idSrc === null || idSrc === "") &&
                "Capture ID"}

              {(idSrc !== null && idSrc !== "") &&
                "Continue"}
            </div>
          </div>
        }

        {!loading && (idType !== "" && idType !== null) && (idSrc !== null && idSrc !== "") &&
          <div className='bottom_'>
            <div className={`btn ${idSrc !== null && idSrc !== "" ? "btn-primary" : ""}`}
              onClick={() => { captureImage() }}
            >
              {(idSrc === null || idSrc === "") &&
                "Capture ID"}

              {(idSrc !== null && idSrc !== "") &&
                "Continue"}
            </div>
          </div>
        }

        {loading &&
          <div className='loading_text text-sm'>
            ...Uploading KYC Document
          </div>
        }
      </div>
    </>
  )
}

export default Kyc