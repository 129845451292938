import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Footnotes(
  // {showTermsNav = true}
) {
  // const navigate = useNavigate()
  return (
    <div>
        <div className='bottom'>Powered by izifin</div>
        {/* {showTermsNav &&
        <div className='text-center text-[9px] 
        cursor-pointer mt-[5px] text-gray-700 hover:underline'
        onClick={()=>navigate('/termsofuse')}
        >IziBnpl Terms of use</div> } */}
    </div>
  )
}
