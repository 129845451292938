import React,{useState, useEffect} from 'react'
import { useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom';

function Logo3({title=""}) {
  const navigate = useNavigate()
  const [companyChecked, setCompanyChecked] = useState(false)
  const {companyDetails
  } = useSelector(
      (state) => state.auth
    );
    useEffect(()=>{
        if(typeof companyDetails !== 'undefined' && JSON.stringify(companyDetails) !== '{}'){
            if(typeof companyDetails.name !== 'undefined'){
                if(companyDetails.name === ''){
                    setCompanyChecked(false)
                }else{
                    setCompanyChecked(true)
                }
            }else{
                setCompanyChecked(false)
            }
        }else{
            setCompanyChecked(false)
        }
    },[companyDetails])
  return (
    <>
    {/* <center> */}
        {companyChecked &&
        <div className='flex cursor-pointer gap-[10px] absolute top-[10px] h-[50px]'
        onClick={()=>{
            navigate('/')
        }}
        >
        <img 
        className="logo3"
        src={ companyDetails.logoUrl} />
        <div
            className='text-[18px] font-medium pt-[12px]'
            style={{color:'var(--primaryColourHexCode)'}}
        >{companyDetails.name}</div>
        </div>
        }
        {!companyChecked &&
            <div className='izi_logo3'></div>
        }
        
    {/* </center> */}
    </>
  )
}

export default Logo3

