import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Logo3 from '../../components/Logo3';
import { useSelector, useDispatch } from 'react-redux';
import {  logoutAsync, userDetailsAsync, getUserWalletAsync } from '../../redux/actions';
import back from '../../assets/back.svg'
import StorageService from '../../utils/storageService';
import { convertToMoney } from '../../utils';

export default function WalletBalance() {
  const dispatch = useDispatch()
  const storageService = new StorageService()
  const {accessToken, companyDetails, userDetails, walletDetails } = useSelector(
      (state) => state.auth
  );
  
  const navigate = useNavigate()
  const accountDetails = {
      "transactions": [],
      "balance": 0,
      "accounts": [
          {
              "accountNumber": "5524126541",
              "customerId": 71,
              "accountProviderId": 1,
              "accountProvider": null,
              "accountMetadata": "{}",
              "accountName": ""
          }
      ],
      "succeeded": false,
      "message": null
  }

  useEffect(()=>{
    let auth_data = storageService.getAuthData()
    if(typeof auth_data.accessToken !== "undefined"){
        dispatch(userDetailsAsync({accessToken: auth_data.accessToken}))
        dispatch(getUserWalletAsync({accessToken: auth_data.accessToken}))
    }
  },[])

  useEffect(()=>{
    console.log(walletDetails)
  },[walletDetails])
    return (
    <div className='main'>
        <div className='izi_box bnpl_modal'>
            <Logo3 />
            {accessToken !== '' && typeof userDetails !== 'undefined' &&
                <div className='logout bg-gray-800'
                    onClick={()=>{
                    dispatch(logoutAsync())
                    }}
                >Logout</div>
            }

                <div className='flex flex-row gap-[20px] py-[5px] mt-[30px] cursor-pointer'>
                    <div className='text-xs 
                    bg-gray-200 py-[4px] px-[10px]
                    flex flex-row rounded-[13px]'
                        onClick={()=>navigate('/payment-options')}
                    >
                        <img src={back} className='w-[15px] h-[15px]'/>
                        <div className=''>Back</div>
                    </div>
                    <div className='text-xs pt-[3px]
                    font-bold
                    '>{`Wallet Balance`}</div>
                </div>
                <div className='w-full h-[60px] bg-gray-800 rounded-[5px] mt-[10px] py-[15px]'>
                  {typeof walletDetails !== "undefined" &&
                    <div className='text-white text-2xl text-center'>
                      {convertToMoney(Number(walletDetails.balance))}
                    </div>
                  }
                </div>
                {typeof walletDetails !== "undefined" &&
                <div className='mt-[20px]'>
                  {typeof walletDetails.accounts !== "undefined" &&
                    <div>
                      {typeof walletDetails.accounts[0] !== "undefined" &&
                        <div>
                            <div className='mt-[20px]'>
                              <div className='text-gray-400 text-xs'>Bank Name</div>
                              <div className='text-black text-sm'>VFD Microfinance Bank</div>
                            </div>
                            <div className='mt-[20px]'>
                              <div className='text-gray-400 text-xs'>Account Number</div>
                              <div className='text-black text-sm'>{walletDetails.accounts[0].accountNumber}</div>
                            </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
        </div>
    </div>
  )
}
